import React, {useEffect} from 'react'
import { SendMailModalProps } from './SendMailModal.props'
import './SendMailModal.styles.css';
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import {AlertMessage} from "../../atoms/AlertMessage";
import {TagInput} from "../../atoms/TagInput";
import { ReactComponent as AttachEmail } from "../../../assets/images/attach_email.svg";
import {isMobileOnly} from "react-device-detect";
import {Editor} from "../../molecules/Editor";

export const SendMailModal: React.FC<SendMailModalProps> = ({
  defaultParams,
  paramSendMail,
  setParamSendMail,
  scanResult
}) => {
  // @ts-ignore
  const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
  const defaultData:any = {
      subject: defaultParams.subject,
      from: userData.email,
      fromName: userData.fullName,
      to: [],
      cc: [],
      originalReporter: true,
      statusToResolved: true,
      content: '',
      base64Data: '',
      originalEmail: '',
      recipients: [],
      reportedBy: defaultParams.reportedBy
  }
  // const onChangeFiles = (e:any) => {
  //   const files = e.target.files;
  //   let base64 = {};
  //   for (let file of files) {
  //     getBase64(file)
  //       .then(result => {
  //         base64[file.name] = result;
  //       })
  //       .catch(err => {
  //         console.log(err);
  //       });
  //   }
  //   console.log(base64)
  //   setParamSendMail(() => {
  //     return  ({
  //       ...paramSendMail,
  //       "base64Data": base64,
  //     })
  //   })
  //
  // };

  // const getBase64 = (file:any) => {
  //   return new Promise(resolve => {
  //     let reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => {
  //       resolve(reader.result);
  //     };
  //   });
  // };

  const handleOnChange=(e:any, type:any) => {
    let item:any = e.target.value;
    if (type === 'originalReporter'){
      item = e.target.checked
    }
    setParamSendMail(() => {
      return  ({
        ...paramSendMail,
        [type]: item,
      })
    })
  }

  useEffect(() => {
    // defaultData.recipients = tags;
    setParamSendMail(defaultData)
  }, []);
  
  let xsValue = 6
  
  if (isMobileOnly) {
    xsValue = 12
  }
  

  return (
    <div className='form-body'>
     {scanResult['scan-result'] === 'Infected' ? <AlertMessage severity='error' content={'This email contains virus or malware which may infect to recipient. Please consider before sending it.'}/> : null}
      <Grid container rowSpacing={1} columnSpacing={{xs: 1, sm: 2, md: 2}}>
        <Grid item xs={xsValue} md={xsValue}>
          <InputLabel htmlFor="from" className='label-input'>From</InputLabel>
          <OutlinedInput id="from" className='form-input' type="text" placeholder='Email address'
                         defaultValue={userData.email} disabled />
        </Grid>
        <Grid item xs={xsValue} md={xsValue}>
          <InputLabel htmlFor="from-name" className='label-input'>From Name</InputLabel>
          <OutlinedInput id="from-name" className='form-input' type="text" placeholder='Enter from name'
                         defaultValue={userData.fullName} onChange={(e: any) => handleOnChange(e, 'fromName')}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputLabel htmlFor="to-field" className='label-input' required>To</InputLabel>
          <TagInput type={'to'} paramSendMail={paramSendMail} setParamSendMail={setParamSendMail}/>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputLabel htmlFor="cc-field" className='label-input'>CC</InputLabel>
          <TagInput type={'cc'} paramSendMail={paramSendMail} setParamSendMail={setParamSendMail} checkboxInput={true}/>
        </Grid>
      </Grid>
      <div>
          <InputLabel htmlFor="subject" className='label-input'>Subject</InputLabel>
          <OutlinedInput id="subject" className='form-input' type="text" placeholder='Enter subject'
                         value={paramSendMail.subject} onChange={(e: any) => handleOnChange(e, 'subject')}/>
      </div>
      <Editor/>
      <InputLabel className='label-input'>The original email is attached as an attachment below:</InputLabel>
      <div className='original-email'>
          <AttachEmail/>
          <label>{defaultData.subject}</label>
      </div>
    </div>
  )
}
