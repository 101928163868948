import React, {useContext, useEffect, useState} from 'react';
import {InboxProps} from './Inbox.props';
import './Inbox.styles.css';
import { GridColDef, GridToolbarColumnsButton, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import {
  getStatusStatistic,
  getTriageInboxes,
  updateTriageInboxes,
  getLatestFilter,
  deleteEmail,
  archiveEmail,
  getInboxMessageEmail, sendMessageEmail, purgeEmail
} from '../../api/emailTriage';
import {SearchMenuBar} from '../../components/organisms/SearchMenuBar';
import { Images } from '../../configs/images';
import {DataTable} from '../../components/molecules/DataTable';
import {toPages} from '../../utils/common';
import moment from 'moment';
import {Button} from '../../components/atoms/Button';
import {Modal} from '../../components/atoms/Modal';
import {PageHeader} from '../../components/organisms/PageHeader';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {GlobalContext} from '../../context/GlobalState';
import {ActionsColumn} from '../../components/molecules/StyledMenu';
import {TableDropdown} from "../../components/molecules/TableDropdown";
import {DateRangePicker} from '../../components/atoms/DateRangePicker';
import {InboxDetails} from '../InboxDetails';
import {TabGroup} from "../../components/molecules/TabGroup";
import {Toast} from "../../components/atoms/Toast";
import {DropdownContent} from "../../components/molecules/DropDownContent";
import {DropDownList} from "../../components/atoms/DropDownList";
import {Tooltip} from "@mui/material";
import {isMobileOnly} from "react-device-detect";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import appConfig from "../../configs/appConfig.json";
import {SendMailModal} from "../../components/organisms/SendMailModal";
import {ARCHIVE, DELETE, PURGE_OPTION, SEND_EMAIL, MESSAGES_STATUS_CHANGED, MESSAGES_PRIORITY_CHANGED} from "../../constants/contants";

const navigationLinks = [
  {
    name: 'Inbox'
  }
]

const inboxDetailLinks = [
  {
    name: "Inbox",
    href: "/inbox",
  },
  {
    name: "Message Details",
  },
];

const inboxDetailArchivedLinks = [
  {
    name: "Archived",
    href: "/archived",
  },
  {
    name: "Message Details",
  },
];

const actionRules:any = {
  "Action Rule 1": "Action Rule 1",
  "Action Rule 2": "Action Rule 2",
  "Action Rule 3": "Action Rule 3",
  "Action Rule 4": "Action Rule 4",
  "Action Rule 5": "Action Rule 5"

}

const actionCategory:any = {
  "Threat": "Threat",
  "Spam": "Spam",
  "Clean": "Clean",
  "Unknown": "Unknown"
}

const statusDropdown = {
  "Received": {bg: "#EDEDED", hover: "#EDEDED"},
  "In Review": {bg: "#E1EFEF", hover: "#E1EFEF"},
  "Resolved": {bg: "#f2fad9", hover: "#f2fad9"},
}

const priorityDropdown:any = {
  "High": {bg: "#ED1C24", hover: "#B20007"},
  "Medium": {bg: "#FCAB10", hover: "#D88E00"},
  "Low": {bg: "#03A9F5", hover: "#007AB2"}
}

const tabStatus: any = {
  '0': 'Received',
  '1': 'In Review',
  '2': 'Resolved',
  '3': 'Archived',
}

export const InboxFull: React.FC<InboxProps> = ({isOpen, refKey, isArchived, setIsArchivedEmail, ...props}) => {
  const { id } = useParams();
  const PERPAGES = 10;
  const [filterParams , setFilterParams] = useState({});
  const [isShowLoading , setShowLoading] = useState(false);
  const [inboxData , setInboxData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<any>("asc");
  const [sortType, setSortType] = useState<any>("priority")
  const [perPages, setPerPages] = useState(PERPAGES);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowSelected, setRowSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [contentModal, setContentModal] = useState({title: '', content: '', action: '', textAgree: '', children: '', variant: ''});
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(0);
  const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0})
  const [statusTab, setStatusTab] = useState({'Received': 0, 'In Review': 0, 'Resolved': 0, 'Archived': 0})
  const [modalID, setModalID] = useState(1)
  const [tabValue, setValue] = useState(0);
  const [inboxDetail, setInboxDetail] = useState({emailId: '', scanResult: '', category: '', status: '', priority: '', subject: '',reportedBy: ''})
  // @ts-ignore
  const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
  const zoneID:any = localStorage.getItem('currentZone');
  const handleChangeTitle = (e:any, type:any) => {
    const typeValue = e.target.getAttribute("value")
    const newRule = {[type]: typeValue}
    updateInbox(newRule)
  }

  const columns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      cellClassName: 'checkbox-col'
    },
    {
      field: 'priority', headerName: 'Priority', width: 120, sortable: true ,
      renderCell: (row) => {
        return isArchived ? <TableDropdown emailId={row.id} title={row.formattedValue} variant='priority' background={priorityDropdown[row.formattedValue]['bg']} backgroundHover={priorityDropdown[row.formattedValue]['hover']} borderRadius={100}/> : <TableDropdown onClick={(e:any) => handleChangeTitle(e, row.field)} emailId={row.id} dropdownValue={setDropdownValue} title={row.formattedValue} variant='priority' background={priorityDropdown[row.formattedValue]['bg']} backgroundHover={priorityDropdown[row.formattedValue]['hover']} borderRadius={100} data={priorityDropdown}/>
      }
    },
    {
      field: 'scan_result',
      headerName: 'Scan Results',
      width: 120,
      sortable: true
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      width: 120,
      renderCell: (row) => {
        return isArchived ? <TableDropdown emailId={row.id} title={row.formattedValue} variant='status' color='#212121' background={statusDropdown[row.formattedValue]['bg'] } backgroundHover={statusDropdown[row.formattedValue]['hover']} borderRadius={2} padding={'4px 4px 4px 8px'} /> : <TableDropdown onClick={(e:any) => handleChangeTitle(e, row.field)} emailId={row.id} dropdownValue={setDropdownValue} title={row.formattedValue} variant='status' color='#212121' background={statusDropdown[row.formattedValue]['bg'] } backgroundHover={statusDropdown[row.formattedValue]['hover']} borderRadius={2} padding={'4px 4px 4px 8px'} data={statusDropdown}/>
      }
    },

    { field: 'category',
      headerName: 'Category',
      width: 120,
      sortable: true,
      renderCell: (row) => {
        return isArchived ? <TableDropdown emailId={row.id} title={row.formattedValue} variant='category' color='#212121' background='transparent' backgroundHover='transparent' borderRadius={100}/> : <TableDropdown onClick={(e:any) => handleChangeTitle(e, row.field)} emailId={row.id} dropdownValue={setDropdownValue} title={row.formattedValue} variant='category' color='#212121' background='transparent' backgroundHover='transparent' borderRadius={100} data={actionCategory}/>
      }
    },
    { field: 'email_from',
      headerName: 'From (Email)',
      width: 260,
      sortable: false,
      renderCell: (row) => {
        return <Tooltip placement={"top"} title={row.formattedValue}>
          <span className={'MuiDataGrid-cellContent'}>{row.formattedValue}</span>
        </Tooltip>
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: isOpen ? 450 : 300,
      sortable: true,
      renderCell: (row) => {
        return <Tooltip placement={"top"} title={row.formattedValue}>
          <span className={'MuiDataGrid-cellContent'}>{row.formattedValue}</span>
        </Tooltip>
      }
    },
    {
      field: 'reported_at',
      headerName: 'Reported at',
      width: 120,
      renderCell: (row) => {
        return (<div>
          <span>{moment(row.formattedValue, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY')}</span>
          <br/>
          <span>{moment(row.formattedValue, 'DD/MM/YYYY hh:mm:ss').format('hh:mm A')}</span>
        </div>)
      }
    },
    {
      field: 'reported_by',
      headerName: 'Reported by (Email)',
      width: 220,
      sortable: false,
      renderCell: (row) => {
        return <Tooltip placement={"top"} title={row.formattedValue}>
          <span className={'MuiDataGrid-cellContent'}>{row.formattedValue}</span>
        </Tooltip>
      }
    },

  ];

  if (isArchived) {
    columns.push({
      field: 'archived_at',
      headerName: 'Archive at',
      width: 180,
      renderCell: (row) => {
        return (<div>
          <span>{moment(row.formattedValue, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}</span>
          <br/>
          <span>{moment(row.formattedValue, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}</span>
        </div>)
      }
    })
  }

  const formatAMPM = (date:any) => {
    var hours = date.hours();
    var minutes = date.minute();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const mobileColumns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      cellClassName: 'checkbox-col',
    },
    {
      field: 'email_from',
      headerName: 'From (Email)',
      sortable: false,
      cellClassName: 'email-content',
      renderCell: (rowCell) => {
        return <div className='info-mobile-row' style={{padding: '10px 0', fontSize: '14px'}}>
          <div className='row first-row'>
            <b>{rowCell.row.email_from}</b>
            <span className={`priority ${rowCell.row.priority}`} style={{borderRadius: '100px', color: '#fff', padding: '4px 8px'}}>{rowCell.row.priority}</span>
          </div>
          <div className='row second-row'>
            <div className='subject'>{rowCell.row.subject}</div>
            <div className='datetime'>
              <span className='date'>{moment(rowCell.row.date_scanned).format('DD/MM/YYYY')}</span>
              <span className='time'>{formatAMPM(moment(rowCell.row.date_scanned))}</span>
            </div>
          </div>
          <div className='row third-row'>
            <label>Status: <span style={{background: '#EDEDED', borderRadius: '2px', color: '#212121', padding:'2px 8px', fontWeight:'600'}}>{rowCell.row.status}</span>&nbsp; | &nbsp;</label>
            <label>Category: <b>{rowCell.row.category}</b></label>
          </div>
        </div>
      }
    }
  ];

  const defaultFilter: any = {
    perPages: PERPAGES,
    page: 1,
    keyword: "",
    priority: ["High", "Medium", "Low", "Unknown"],
    category: ["Threat", "Clean", "Spam", "Unknown"],
    scans: ["Infected", "Unscannable", "Error", "Clean"],
    sort: "" ,// ASC / DESC, default is ASC,
    sortType: "priority" ,// default is date time,
    startDate: moment().utc().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dropdownValue, setDropdownValue] = useState<any>('');
  const open = Boolean(anchorEl);
  const [filterStatus, setFilterStatus] = useState<any>({status: ['Received']});
  const [inboxStatistic, setInboxStatistic] = useState<any>({});


  let sDate = moment().utc().subtract(1, 'months').format('MM/DD/YYYY');
  let eDate = moment().utc().format('MM/DD/YYYY');
  const filter = sessionStorage.getItem("filter");
  if (filter !== null) {
    const filterData = JSON.parse(filter);
    sDate = moment(filterData.startDate).format('MM/DD/YYYY');
    eDate = moment(filterData.endDate).format('MM/DD/YYYY');
  }

  const [dates, setDates] = useState({
    startDate: sDate,
    endDate: eDate,
    sDateFormatted: moment().utc().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
    eDateFormatted: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  });
  const [filterDataResult, setFilterDataResult] = useState<any>(defaultFilter);
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [inboxEvent, setInboxEvent] = useState<any>({id: id || '', selectedEmail: ''});

  const resetFilter = () => {
    setSelectedItems({})
    const dateFormatted = {
      startDate: dates.sDateFormatted,
      endDate: dates.eDateFormatted
    }

    Object.assign(defaultFilter, dateFormatted, {status: filterStatus.status}, {
        perPages: PERPAGES,
        page: 1,
        keyword: "",
        priority: ["High", "Medium", "Low", "Unknown"],
        category: ["Threat", "Clean", "Spam", "Unknown"],
        scans: ["Infected", "Unscannable", "Error", "Clean"],
        sort: "" ,// ASC / DESC, default is ASC,
        sortType: "priority" ,// default is date time,
    })
    setCurrentPage(1);
    setInboxData([]);
    getInboxes(1, defaultFilter);
    getStatusTab(defaultFilter);
    getStatusTab(defaultFilter);

    sessionStorage.setItem("filter", JSON.stringify(defaultFilter));
  }

  const showActionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
    const emailId:any = event.currentTarget.getAttribute('data-emailid');
    const rowId = document.querySelector('[data-id="'+emailId+'"]');
    if(rowId) {
      rowId.classList.add('focusing')
    }

    setAnchorEl(event.currentTarget);
    getInboxDetail(emailId)
  };
  const hideActionsDropdown = () => {
    const gridRows = document.querySelectorAll('.MuiDataGrid-row');
    if(gridRows) {
      for(let i = 0; i < gridRows.length; i++) {
        gridRows[i].classList.remove('focusing');
      }
    }
    setAnchorEl(null);
  };

  const [columnData, setColumnData] = useState<any>(null)

  const getFilterColumn = (data:object) => {
    return getLatestFilter(data).then((result) => {
      const a = result.data;
      if (a === "nodata") {
        sessionStorage.setItem("dynamicColumn", JSON.stringify({}));
        setColumnData({})
      } else {
        sessionStorage.setItem("dynamicColumn", a.dynamic_column);
        setColumnData(JSON.parse(a.dynamic_column))
      }
      return a;
    });
  }

  const loadDataTable = () => {
    const userData = localStorage.getItem('userData');
    if (userData === null) {
      localStorage.clear();
      let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL;

      const isLogout = sessionStorage.getItem('isLogout');

      if (isLogout==='company_admin') {
        loginLink += "/company_admin";
      } else {
        loginLink += "/admin";
      }
      window.location.href = loginLink;
    } else {
      const userId = JSON.stringify(JSON.parse(userData)["userId"]);

      const filter = sessionStorage.getItem("filter");

      if (filter === null) {
        const dateFormatted = {
          startDate: dates.sDateFormatted,
          endDate: dates.eDateFormatted
        }

        Object.assign(defaultFilter, filterStatus, dateFormatted)
        getInboxes(1, defaultFilter)
        getStatusTab(defaultFilter)
        getFilterColumn({"userId": userId})
        sessionStorage.setItem("filter", JSON.stringify(defaultFilter));

      } else {
        const filterData = JSON.parse(filter);

        setDates((prev:any) => {
          return ({
            ...prev,
            startDate: moment(filterData.startDate).format('MM/DD/YYYY'),
            endDate: moment(filterData.endDate).format('MM/DD/YYYY'),
            sDateFormatted: filterData.startDate,
            eDateFormatted: filterData.endDate
          })
        })

        setSelectedItems((prev:any) => {
          return ({
            ...prev,
            "priority": filterData.priority.length === 4? []:filterData.priority,
            "category": filterData.category.length === 4? []:filterData.category,
            "scans": filterData.scans.length === 4? []:filterData.scans

          })
        })
        Object.assign(defaultFilter, filterData, filterStatus)
        if(isArchived){
          setActiveFilter('Archived')
        }
        getInboxes(1, defaultFilter)
        getStatusTab(defaultFilter)
        getFilterColumn({"userId": userId})
        sessionStorage.setItem("filter", JSON.stringify(defaultFilter));
      }
    }
  }

  useEffect(() => {
    loadDataTable()
  },[])

  const navigate = useNavigate();
  const location = useLocation();

  let { globalDispatch } = useContext(GlobalContext);

  const rowParams = (rowUpdated:any, newRule:any) => {
    if(rowUpdated) {
      return {
        "emailId": rowUpdated.email_id,
        "category": newRule?.category || rowUpdated.category,
        "priority": newRule?.priority || rowUpdated.priority,
        "status": newRule?.status || rowUpdated.status
      }
    }
  }

  const updateInbox = (newRule:any) => {
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    let rowUpdated:any;
    if(rowEmailId) {
      rowUpdated = inboxData.find((row:any) => row.email_id === rowEmailId.toString());
    }
    if(rowUpdated) {
      const params = rowParams(rowUpdated, newRule)
      const inboxUpdated:any = inboxData.map((row:any) => {
        return row.email_id === rowEmailId ? { ...row, priority: params?.priority, status: params?.status, category: params?.category }: row;
      });
      setInboxData(inboxUpdated);

      return updateTriageInboxes(params).then((result) => {
        if(result.status === 200) {
          setRowSelected([])
        }
      })
    } else {
      const selectedRowsData = rowSelected.map((id:any) => inboxData.find((row:any) => row.email_id === id));
      let inboxesUpdated:any;

      inboxesUpdated = inboxData.map((rowData:any) => {
        const found = selectedRowsData.find((rowS: any) => rowS.email_id === rowData.email_id);
        if (found) {
          const rowParam = rowParams(found, newRule)
          rowData = Object.assign(rowData, {priority: rowParam?.priority, status: rowParam?.status, category: rowParam?.category});
          updateTriageInboxes(rowParam).then((result) => {
            if(result.status === 200) {
              setRowSelected([])
            }
          })
        }
        return rowData;
      });
      setInboxData(inboxesUpdated)
    }
  }

  const getInboxes = (page: number, params: any, perPageNav?: number, onSort?: string, sortTypeTable?: string) => {
    setShowLoading(true);
    params["page"] = page;
    params["sort"] = onSort || sort;
    params["perPages"] = perPageNav || perPages;
    params["sortType"] = sortTypeTable || sortType;

    return getTriageInboxes(params).then((result) => {
      const a = result.data;
      setShowLoading(false);
      setInboxData(a.list);
      setTotalRows(a.totalItems);
      setStartPoint(a.startItemsPerPage);
      setEndPoint(a.endItemsPerPage)
      setTotalPages(a.maxPage);
      setInboxStatistic(a.statistic)
      hideActionsDropdown()
      // setToastValue({type: 'success', message: 'Load Successfully', isOpen: true, toastId: a.list.length !== 0 ? a.list[0].status : ''})
      return a;
    });
  };

  const getStatusTab = (params: any) => {
    getStatusStatistic(params).then((result) => {
      const data = result.data;
      setStatusTab({'Received': data.totalStaReceived, 'In Review': data.totalStaReview, 'Resolved': data.totalStaResolved, 'Archived': data.totalArchive})
    })
  }

  const setActiveFilter = (item:any) => {
    const filter = sessionStorage.getItem("filter");
    setFilterStatus((prev:any) => {
      return ({
        ...prev,
        status: [item]
      })
    })
    //@ts-ignore
    const filterData = JSON.parse(filter);
    filterData.status = [item]
    
    setSelectedItems((prev:any) => {
      return ({
        ...prev,
        "priority": filterData.priority.length === 4? []:filterData.priority,
        "category": filterData.category.length === 4? []:filterData.category,
        "scans": filterData.scans.length === 4? []:filterData.scans
      })
    })
    Object.assign(defaultFilter, {status: [item]}, filterData)

    setFilterDataResult(defaultFilter);
    getInboxes(1, defaultFilter);
    getStatusTab(defaultFilter)
  }

  const handleFilter = (params?:any) => {
    const dateFormatted = {
      startDate: dates.sDateFormatted,
      endDate: dates.eDateFormatted
    }
    Object.assign(params, {status: filterStatus.status}, dateFormatted)
    setCurrentPage(1);
    setInboxData([]);
    getInboxes(1, params);
    getStatusTab(params)
  }

  const handlePageChange = (page: number) => {
    const dateFormatted = {
      startDate: dates.sDateFormatted,
      endDate: dates.eDateFormatted
    }
    Object.assign(defaultFilter, {status: filterStatus.status}, dateFormatted)
    setCurrentPage(page);
    getInboxes(page, defaultFilter);
    getStatusTab(defaultFilter);
  }

  const handlePerRowsChange = (e:any) => {
    const dateFormatted = {
      startDate: dates.sDateFormatted,
      endDate: dates.eDateFormatted
    }
    setPerPages(parseInt(e.target.value));
    setCurrentPage(1);
    Object.assign(defaultFilter, filterParams, dateFormatted, {status: filterStatus.status})
    getInboxes(1, defaultFilter, parseInt(e.target.value));
    getStatusTab(defaultFilter)
  }
  const actionOtherData:any = isArchived ? {
    "Delete": DELETE,
    } : {
      "SendMail": SEND_EMAIL,
      // "Purge": PURGE_OPTION,
      "Archive": ARCHIVE,
      "Delete": DELETE,
    }

  const customToolbar = () => {
    return (
      <div className='toolbar'>
        <div className={"left-toolbar " + (disabledActions ? 'hide-left-toolbar': 'show-left-toolbar')}>
          {disabledActions ? '' :
            <div className={"rows-selected"}>
              <Button
                linkIcon={Images.CloseIcon}
                margin={"0 13px 0  0"}
                backgroundColor={"transparent"}
                variant={'only-icon'}
                fontSize={14}
                width={24}
                iconWidth={14}
                iconHeigth={14}
                color={'#011111'}
                onClick={() => setRowSelected([])}
              />
              <span>{totalRowsSelected} Selected</span>
            </div>
          }
          <DropDownList
            className={`${isArchived ? 'is_archived' : ''}`}
            width={124}
            height={36}
            border={"none"}
            variantDropdown={'actions-dropdown-inbox'}
            selectedTitle={'Actions'}
            disabled={(disabledActions)}
            children={<DropdownContent open={true} menuData={rowSelected} actionOtherData={actionOtherData} handleOpenSendMail={handleSendMail} handleDeleteMail={handleActionDelete} handleArchiveMail={handleArchiveMail} handlePurgeMail={handlePurgeMail}/>}
          />
        </div>
        {!isMobileOnly ? <div className={"right-toolbar"}>
          <GridToolbarColumnsButton startIcon={null}/>
        </div> : null}
      </div>
    )
  }

  const paginationComponent = () => {

    const perRowsPages = [5, 10, 20];
    const pageItems = toPages(totalPages);
    const nextDisabled = currentPage === pageItems.length ? 'disabled': '';
    const previousDisabled = currentPage === 1 ? 'disabled': '';

    return <nav className='pagination'>
      <div className='pagination-flex'>

        <span className='rowPerPages' style={{marginRight: 8}}>Rows per page</span>
        <select value={perPages} onChange={handlePerRowsChange} style={{width: 59}}>
          {
            perRowsPages.map(page => <option key={page} value={page}>{page}</option>)
          }
        </select>

        <span className='totalRows' style={{marginRight: 32, marginLeft: 32}}> {startPoint}-{endPoint} of {totalRows}  </span>

        <ul className="pagination-numbers">
          <li onClick={() => handlePageChange(1)} className={'first-page ' + previousDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></li>
          <li onClick={() => handlePageChange(currentPage - 1)} className={'previous-page ' + previousDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></li>
          <li onClick={() => handlePageChange(currentPage + 1)} className={'next-page ' + nextDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></li>
          <li onClick={() => handlePageChange(pageItems.length)}  className={'last-page ' + nextDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></li>
        </ul>
      </div>
    </nav>
  }

  const onSelectedRowsChange = (rows:any) => {
    const selectedRowsData = rows.map((id:any) => inboxData.find((row:any) => row.email_id === id));
    setRowSelected(selectedRowsData);
  }

  const onSort = (model:any , event: any) => {
    const dateFormatted = {
      startDate: dates.sDateFormatted,
      endDate: dates.eDateFormatted
    }
    if (filter !== null) {
      const filterData = JSON.parse(filter);
      setSelectedItems((prev:any) => {
        return ({
          ...prev,
          "priority": filterData.priority.length === 4? []:filterData.priority,
          "category": filterData.category.length === 4? []:filterData.category,
          "scans": filterData.scans.length === 4? []:filterData.scans
        })
      })
      Object.assign(defaultFilter, filterData)
    }
    setSort(model?.length !== 0 ? model[0].sort : 'asc')
    setSortType(model?.length !== 0 ? model[0].field : 'date');
    Object.assign(defaultFilter, {status: filterStatus.status}, dateFormatted)
    getInboxes(1, defaultFilter, perPages,model?.length !== 0 ? model[0].sort : 'asc', model?.length !== 0 ? model[0].field : 'date');
    getStatusTab(defaultFilter)
  }

  const onSearchInboxByKeywords = (event:any) => {
    if(event.keyCode === 13) {
      Object.assign(defaultFilter, filterParams)
      defaultFilter['keyword'] = event.target.value
      handleFilter(defaultFilter)
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDoubleClickItem = (event:any) =>{
    const fields = ['category', '__check__', 'status', 'priority']
    if(!fields.includes(event.field)) {
      globalDispatch({
        type: 'UPDATE_SELECTED_EMAIL',
        payload: event.row
      })
      setInboxEvent({id: event.id, selectedEmail: event.row})
      setIsArchivedEmail(event.row.is_archive)
      if(event.row.is_archive){
        navigate('/archived/' + event.id, {
          state: {
            selectedEmail: event.row
          }
        })
      } else {
        navigate('/inbox/' + event.id, {
          state: {
            selectedEmail: event.row
          }
        })
      }

    }
  }

  const handleApplyDateRange = (picker:any) => {

    setDates((prev:any) => {
      return ({
        ...prev,
        startDate: moment(picker.startDate).format('MM/DD/YYYY'),
        endDate: moment(picker.endDate).format('MM/DD/YYYY'),
        sDateFormatted: picker.startDate,
        eDateFormatted: picker.endDate
      })
    })
    const filter = sessionStorage.getItem("filter");

    if (filter !== null) {
      const filterData = JSON.parse(filter);
      setSelectedItems((prev:any) => {
        return ({
          ...prev,
          "priority": filterData.priority.length === 4? []:filterData.priority,
          "category": filterData.category.length === 4? []:filterData.category,
          "scans": filterData.scans.length === 4? []:filterData.scans
        })
      })
      Object.assign(defaultFilter, filterData)
    }if (filter !== null) {
      const filterData = JSON.parse(filter);
      setSelectedItems((prev:any) => {
        return ({
          ...prev,
          "priority": filterData.priority.length === 4? []:filterData.priority,
          "category": filterData.category.length === 4? []:filterData.category,
          "scans": filterData.scans.length === 4? []:filterData.scans
        })
      })
      Object.assign(defaultFilter, filterData)
    }
    Object.assign(defaultFilter, {status: filterStatus.status}, {startDate: moment(picker.startDate).format('YYYY-MM-DD HH:mm:ss'), endDate: moment(picker.endDate).format('YYYY-MM-DD HH:mm:ss')})
    setCurrentPage(1);
    setInboxData([]);
    getInboxes(1, defaultFilter);
    getStatusTab(defaultFilter)

    sessionStorage.setItem("filter", JSON.stringify(defaultFilter));
  }

  const handleActionDelete = (params?:any) => {
    const emailItems:any = []
    let deleteParams:any = {
      emailItems: []
    }
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    if(rowSelected.length !== 0 && ((typeof params) === 'object' && params.length === 0 && rowEmailId === '')){
      // multi checkbox
      rowSelected.map((id:any) => emailItems.push({
        "email_id": id.toString(),
        "zone_id": zoneID.toString()
      }));
      deleteParams['emailItems'] = emailItems
    } else if(rowEmailId.length !== 0 && (typeof params) === 'object'){
      // action column
      deleteParams['emailItems'].push({
        "email_id": rowEmailId,
        "zone_id": zoneID.toString()
      })
    } else {
      // action in message details
      deleteParams['emailItems'] = params
    }
    setAnchorEl(null)
    return deleteEmail(deleteParams).then((result:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: 'Delete email successfully', isOpen: true, toastId: modalID})
      if (params && isArchived){
        setInboxEvent({id: '', selectedEmail: ''})
        navigate('/archived')
      } else {
        navigate('/inbox')
      }
      loadDataTable()
    });
  }

  const handleEmailChange = (params?:any) => {
    loadDataTable()
  }

  const handleArchiveMail = (params?:any) => {
    const emailItems:any = []
    let archiveParams:any = {
      archived_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      itemsArchive: []
    }
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    if(rowSelected.length !== 0 && ((typeof params) === 'object' && params.length === 0 && rowEmailId === '')){
      // multi checkbox
      rowSelected.map((id:any) => emailItems.push({
        "email_id": id.toString(),
      }));
      archiveParams['itemsArchive'] = emailItems
    } else if(rowEmailId.length !== 0 && (typeof params) === 'object'){
      // action column
      archiveParams['itemsArchive'].push({
        "email_id": rowEmailId,
      })
    } else {
      // action in message details
      archiveParams['itemsArchive'] = params
    }
    setAnchorEl(null)
    return archiveEmail(archiveParams).then((result:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: 'Archive email successfully', isOpen: true, toastId: modalID})
      if (params){
        navigate('/inbox')
      }
      loadDataTable()
    });
  }

  const getInboxDetail = (params:any) => {
    inboxData.map((row:any) => {
      return row.email_id === params ? setInboxDetail({
        emailId: params,
        subject: row?.subject,
        reportedBy: row?.reported_by,
        priority: row?.priority,
        status: row?.status,
        category: row?.category,
        scanResult: row?.scan_result }) : row;
    });
  }

  // effect when clicking checkbox
  useEffect(() => {
    const params = rowSelected.join('')
    if (rowSelected.length === 1){
      getInboxDetail(params)
    } else {
      setInboxDetail({emailId: '', subject: '', reportedBy: '', priority: '', status: '', category: '', scanResult: ''})
    }
  }, [rowSelected])

  // effect when clicking archived page
  useEffect(() => {
    if(isArchived){
      setActiveFilter('Archived')
      if(!(location.pathname.split('/archived/').length === 2)){
        setInboxEvent({id: '', selectedEmail: ''})
      }
    } else {
      setActiveFilter(tabStatus[tabValue])
    }
  }, [isArchived])

  const defaultParams: any = {
    subject: inboxDetail.subject,
    from: userData.email,
    fromName: userData.fullName,
    to: [],
    cc: [],
    originalReporter: true,
    statusToResolved: true,
    content: '',
    base64Data: '',
    originalEmail: '',
    recipients: [],
    reportedBy: inboxDetail.reportedBy
  }

  const [paramSendMail, setParamSendMail] = useState(defaultParams)
  const [openSendMail, setOpenSendMail] = useState(false)
  const closeSendMail = () => {setOpenSendMail(!openSendMail)}

  const handleSendMail = () => {
    setOpenSendMail(!openSendMail)
    setAnchorEl(null)
    hideActionsDropdown()
  }

  const validatePopupSendMail = () => {
    if (!(paramSendMail['to'].length === 0)){
      sendMail(paramSendMail)
    } else {
      setModalID(modalID + 1)
      setToastValue({type: 'error', message: 'Please input "To" field', isOpen: true, toastId: modalID})
    }
  }

  const sendMail:any = (paramSendMail:object) => {
    const editorValue = document.getElementsByClassName("jodit-wysiwyg");
    const changeStatusParams = {
      emailId: inboxDetail.emailId,
      status: 'Resolved',
      priority: inboxDetail.priority,
      category: inboxDetail.category
    }
    if(editorValue)
      paramSendMail['content'] = editorValue[0].innerHTML;
    paramSendMail['emailId'] = inboxDetail.emailId;
    paramSendMail['zoneId'] = zoneID.toString();

    setOpenSendMail(false);
    return sendMessageEmail(paramSendMail).then((result:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: result.data, isOpen: true, toastId: modalID})
      if(paramSendMail['statusToResolved']){
        return updateTriageInboxes(changeStatusParams).then((result:any) => {
          loadDataTable()
        });
      }
    });
  };

  const handlePurgeMail = (params?:any) => {
    const emailItems:any = []
    let deleteParams:any = {
      emailItems: []
    }
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    if(rowSelected.length !== 0 && ((typeof params) === 'object' && params.length === 0 && rowEmailId === '')){
      // multi checkbox
      rowSelected.map((id:any) => emailItems.push({
        "email_id": id.toString(),
        "zone_id": zoneID.toString()
      }));
      deleteParams['emailItems'] = emailItems
    } else if(rowEmailId.length !== 0 && (typeof params) === 'object'){
      // action column
      deleteParams['emailItems'].push({
        "email_id": rowEmailId,
        "zone_id": zoneID.toString()
      })
    } else {
      // action in message details
      deleteParams['emailItems'] = params
    }
    setAnchorEl(null)
    // console.log(deleteParams)
    // return purgeEmail(deleteParams).then((result:any) => {
    //   setModalID(modalID + 1)
    //   setToastValue({type: 'success', message: 'Delete email successfully', isOpen: true, toastId: modalID})
    //   if (params){
    //     navigate('/inbox')
    //   }
    //   loadDataTable()
    //   openNoticePopup('success')
    // });
    // fake data
    var random = Math.round(Math.random());
    var status = ''
    random < 0.5 ? status = 'success' : status = 'delete'
    openNoticePopup(status)
  }

  const openNoticePopup = (status:any) => {
    let title = ""
    let content_html = ""
    if(status === "success"){
      title = "Removed emails successfully!"
      content_html = `<div>Threat emails have been permanently removed from users’ inbox.</div>
                      <div class="notes">
                          <div>${MESSAGES_STATUS_CHANGED}: <span>Resolved</span></div>
                          <div>${MESSAGES_PRIORITY_CHANGED}: <span>Low</span></div>
                      </div>`
    } else {
      title = "Failed to remove emails"
      content_html = `<div>There was an issue when removing the emails.</div>
                      <div>Please try again later.</div>`
    }

    const content:any = {
      title: title,
      children: <div dangerouslySetInnerHTML={{ __html: content_html }} />,
      action: '',
      textAgree: 'OK',
      variant: status
    }
    setOpenDialog(true);
    setContentModal(content);
  }

  const totalRowsSelected =  rowSelected.length
  const disabledActions = rowSelected.length === 0;
  const aoColumns = <ActionsColumn isArchive={isArchived} menuData={inboxData} rowSelected={[inboxDetail.emailId]} open={open} showActionsDropdown={showActionsDropdown} actionRules={actionRules} hideActionsDropdown={hideActionsDropdown} anchorEl={anchorEl} handleDelete={handleActionDelete} handleArchive={handleArchiveMail} handleSendMail={handleSendMail} handlePurgeMail={handlePurgeMail} actionOtherData={actionOtherData}/>
  const isInboxPage = inboxEvent['id'] && window.location.pathname !== '/inbox'
  const isArchivedPage = inboxEvent['id'] && isArchived
  // console.log(isInboxPage, isArchived, inboxEvent, window.location.pathname)
  return <div className='inbox-page page-content-wrapper'>
    <PageHeader setInboxEvent={setInboxEvent} pageTitle={isInboxPage ? 'Message Details': isArchived ? 'Archived' : 'Inbox'} breadCrumbData={isInboxPage ? (isArchivedPage ? inboxDetailArchivedLinks : inboxDetailLinks) : navigationLinks} children={isInboxPage ? '':  <DateRangePicker
      start={sDate}
      end={eDate}
      fontSize={'14px'}
      color={'#011111'}
      colorText={'#011111'}
      width={'165px'}
      height={'36p'}
      cssClass={'daterange-input'}
      dates={dates}
      handleApplyDateRange={handleApplyDateRange}
    />}/>
    {
      isInboxPage || isArchivedPage ? <InboxDetails
        emailId={inboxEvent['id']}
        selectedRowEmail={inboxEvent['selectedEmail']}
        handleDelete={handleActionDelete}
        handleArchiveMail={handleArchiveMail}
        tabValue={tabValue}
        rowSelected={rowSelected}
        actionOtherData={actionOtherData}
        handleSendMail={handleSendMail}
        handleActionDelete={handleActionDelete}
        handleEmailChanged={handleEmailChange}
        handlePurgeMail={handlePurgeMail}
      /> : <div >

        <div className='page-content-body'>
          {/*<SummaryStatistics setActiveFilter={setActiveFilter} active={filterStatus.type} arrList={inboxStatistic || []} onFilter={handleFilter}></SummaryStatistics>*/}
          <div className='inbox-content'>
            {isMobileOnly ? <div className='search-inbox'>
              <SearchMenuBar setSelectedItems={setSelectedItems} selectedItems={selectedItems} resetFilter={resetFilter} defaultFilter={defaultFilter} handleEnterSearch={onSearchInboxByKeywords} onFilter={handleFilter}/>
            </div> : null}
            {isArchived ? null : <TabGroup setValue={setValue} tabValue={tabValue} tabTitles={['Received', 'In Review', 'Resolved']} tabContents={[]} setActiveFilter={setActiveFilter} statistic={statusTab}/>}
            {!isMobileOnly ? <div className='search-inbox'>
              <SearchMenuBar setSelectedItems={setSelectedItems} selectedItems={selectedItems} resetFilter={resetFilter} defaultFilter={defaultFilter} handleEnterSearch={onSearchInboxByKeywords} onFilter={handleFilter}/>
            </div> : null}
            <div className='inbox-datatable'>
              {columnData && <DataTable columns={isMobileOnly ? mobileColumns : columns}
                         checkNoFilterColumn ={columnData}
                         data={inboxData}
                         filterData={columnData}
                         rowCount={totalRows}
                         onSelectionModelChange={setRowSelected}
                         CustomPagination={paginationComponent}
                         onChangeRowsPerPage={onSelectedRowsChange}
                         onRowDoubleClick={handleDoubleClickItem}
                         toolbar={customToolbar}
                         actionsColumn={!isMobileOnly && aoColumns}
                         selectionModel={rowSelected}
                         onSortModelChange={onSort}
                         loadingTable={isShowLoading}
              />}
            </div>
          </div>
        </div>

        
      </div>
    }
    <Modal openDialog={openDialog}
           variant={contentModal.variant}
           title={contentModal.title}
           content={contentModal.content}
           textAgree={contentModal.textAgree}
           actionAgree={() => {
             handleClose()
           }}
           onClose={handleClose}
           children={contentModal.children}
    />
    <Modal idModal='modal-send-mail'
           variant='send-mail'
           disableEnforceFocus={true}
           openDialog={openSendMail}
           title={'Send Mail'}
           actionAgree={validatePopupSendMail}
           actionCancel={closeSendMail}
           textAgree={'Send'}
           textCancel={'Cancel'}
           onClose={closeSendMail}
           handleOnChange={(e:any) => setParamSendMail(() => {
             return  ({
               ...paramSendMail,
               'statusToResolved': e.target.checked,
             })
           })}
    >
      {inboxDetail.subject && <SendMailModal defaultParams={defaultParams} paramSendMail={paramSendMail} setParamSendMail={setParamSendMail} scanResult={{'scan-result': inboxDetail.scanResult}}/>}
    </Modal>
    <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
  </div>

};


export const Inbox = ChangeWithZone(InboxFull);
