import React, {useContext, useEffect, useState} from 'react';

import {ZoneSwitcherProps} from "./ZoneSwitcher.props";
import {CustomDropDown} from "../../molecules/CustomDropDown";
import './ZoneSwitcher.style.css';
import {GlobalContext} from "../../../context/GlobalState";


function findById(array:any, id:any) {
  for (const item of array) {
    if (item.id.toString() === id.toString()) return item;
    if (item.children?.length) {
      const innerResult:any = findById(item.children, id);
      if (innerResult) return innerResult;
    }
  }
}

export  const ZoneSwitcher: React.FC<ZoneSwitcherProps> = ({dataSource=[], ...props}) => {
    const [dataList, setDataList] = useState<any[]>([])
    const [masterNode, setMasterNode] = useState<any>({})
    const rawDataSource = dataSource;
    let currentZone  = localStorage.getItem('currentZone') || ''
    let { globalDispatch, reloadDispatcher } = useContext(GlobalContext);

    const getNestedChild = (result:any, node:any) => {
        result.push(node)
        if (node.children.length === 0) {
          return
        }
        node.children.forEach((item:any, index:any) => {
          getNestedChild(result, item)
        })
    }

    const serializeTree = (tree:any, node:any) => {
        const result:any = []
        let tempTree:any = []
        if (!node) { return []}
        const pathArr = node.path.trim().split(' ')
        pathArr.forEach((item:any, index:number) => {
            item = parseInt(item)
            if (tempTree.length === 0) {
                tempTree = tree[0]
                let _child:any = []
                getNestedChild(_child, tree[0])
              let childList: any = [];
              _child.forEach((element:object)=>{
                childList.push(JSON.stringify(element["id"]))
              })
              // console.log(childList)
              
                // _child.shift()
                result.push({
                    master: {id: tempTree.id, name: tempTree.name, path: tempTree.path},
                    child: _child
                })
            } else {
                tempTree = tempTree.children[item]
                 let _child:any = []
                getNestedChild(_child, tempTree)
                let childList: any = [];
                _child.forEach((element:object)=>{
                  childList.push(element["id"])
                })
              // console.log(childList)
                // _child.shift()
                result.push({
                    master: {id: tempTree.id, name: tempTree.name, path: tempTree.path},
                    child: _child
                })
            }
        })
        return result
    }

    useEffect(() => {
      setDataList(rawDataSource)
      let currentMaster = findById(rawDataSource, parseInt(currentZone))
      if (currentMaster) {
        setMasterNode({id: currentMaster.id, name: currentMaster.name, path: currentMaster.path})
      } else {
        setMasterNode({id: rawDataSource[0]?.id, name: rawDataSource[0]?.name, path: rawDataSource[0]?.path})
      }
    }, [dataSource]);

    const onItemClick = (node:any):void => {
      setMasterNode({id: node.id, name: node.name, path: node.path})
          globalDispatch({
            type: 'UPDATE_SELECTED_ZONE_ID',
            payload: node.id
          })
          localStorage.setItem('currentZone', node.id)
          localStorage.setItem('fromChangeNode', "1")
          reloadDispatcher(node.id)
    }

    return (
      <div className="zone-tree-wrapper">
        <CustomDropDown index={1} masterNode={masterNode} dataSource={dataList} onItemClick={onItemClick} />
      </div>
    );
};
