import React from 'react'
import {InboxDetailsHeaderProps} from './InboxDetailsHeader.props'
import './InboxDetailsHeader.styles.css'
import {Typography} from "@mui/material";
import Divider from '@mui/material/Divider';
import {ControlDisplay} from "../../molecules/ControlDisplay";
import AttachmentIcon from '@mui/icons-material/Attachment';
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {isMobileOnly} from "react-device-detect";

const headerDetailStyle = {
    fontSize: 14
}

const footerDetailStyle = {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 2,
    columnGap: 4,
    fontSize: 12
}

export const InboxDetailsHeader: React.FC<InboxDetailsHeaderProps> = (props) => {
    const header = props.header;
    const theme = useTheme();

    return (
        <>
          {isMobileOnly ? <Typography sx={{fontWeight: '500', color: '#909090', fontSize: '12px'}} variant={'body2'}>{header?.email}</Typography> : ""}
  
          <Box sx={{display: 'inline-flex', alignItems: 'top', mb: 2, width:'100%', boxSizing: 'border-box'}}>
    
              {header?.hasAttachment ? <AttachmentIcon color={'secondary'} sx={{mr: 1}}/> : null}
              {
                header?.subject !== undefined ? (
                  <Typography sx={{fontWeight: '700', fontSize: '20px'}}>{header?.subject}</Typography>
                ): (
                  <Skeleton variant="text" height={40} sx={{fontSize: '1rem', width: '50%'}}/>
                )
              }

            </Box>
            <Box sx={headerDetailStyle} >
              
              {isMobileOnly ? <Accordion sx={{
                backgroundColor: ' #F5F5F5',
                borderRadius: '4px',
                boxShadow: 'none',
                boxSizing: 'border-box'
              }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color={'primary'}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography sx={{fontSize: '14px', fontWeight: '600'}}>Email Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
    
                  <ControlDisplay
                    label={'Reply To: '}
                    labelAlign={'left'}
                    labelColor={theme.palette.black.light}
                    contentColor={"#011111"}
                    variant={'caption'}
                    content={header.replyTo}
                    contentFontWeight={'500'}
                    labelWidth='80px'/>
                  <ControlDisplay
                    label={'To: '}
                    labelAlign={'left'}
                    labelColor={theme.palette.black.light}
                    contentColor={"#011111"}
                    variant={'caption'}
                    content={header.to}
                    contentFontWeight={'500'}
                    labelWidth='80px'/>
                  <Divider sx={{my: 2}} light/>
                  <Box sx={footerDetailStyle}>
                    <ControlDisplay
                      contentColor={"#011111"}
                      boxMb={0}
                      boxDirection={'row'}
                      label={'Reported at:'}
                      content={header?.reportedAt}
                      variant={'caption'}
                      contentFontWeight={'500'}
                      labelWidth='80px'
                    />
                    <ControlDisplay
                      contentColor={"#011111"}
                      boxMb={0}
                      variant={'caption'}
                      boxDirection={'row'}
                      label={'Reported by:'}
                      content={header?.reportedBy}
                      contentFontWeight={'500'}
                      labelWidth='80px'
                    />
                  </Box>
  
                </AccordionDetails>
              </Accordion> : <Box>
                <ControlDisplay label={'From: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.email} labelWidth='70px'/>
                <ControlDisplay label={'Reply To: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.replyTo} labelWidth='70px'/>
                <ControlDisplay label={'To: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.to} labelWidth='70px'/>
                <ControlDisplay label={'CC: '} labelAlign={'right'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={header.cc} labelWidth='70px'/>
                <Divider sx={{my: 2}} light/>
                <Box sx={footerDetailStyle}>
                  <ControlDisplay
                    boxMb={0}
                    boxDirection={'column'}
                    label={'Reported at'}
                    content={header?.reportedAt}
                    contentFontWeight={'700'}
                    variant={'caption'}
                    contentFontStyle={'Italic'}
                  />
                  <ControlDisplay
                    boxMb={0}
                    variant={'caption'}
                    boxDirection={'column'}
                    label={'Reported by'}
                    content={header?.reportedBy}
                    contentFontWeight={'700'}
                    contentFontStyle={'Italic'}
                  />
                </Box>
              </Box>}
            </Box>
           
        </>
    )
}
