import React, {useState, useContext, useEffect} from 'react'
import { InboxDetailsActionProps } from './InboxDetailsAction.props'
import './InboxDetailsAction.styles.css'
import {Box, Stack, Typography} from "@mui/material";
import Divider from '@mui/material/Divider';
import {GlobalContext} from '../../../context/GlobalState';
import DropDownSelect from "../../molecules/DropDownSelect/DropDownSelect";
import {ARCHIVE, CONSTANTS, SEND_EMAIL, DELETE, PURGE_OPTION} from "../../../constants/contants";
import {sendMessageEmail, updateInboxMessage, updateTriageInboxes} from "../../../api/emailTriage";
import {Modal} from "../../atoms/Modal";
import {SendMailModal} from "../SendMailModal";
import {DropDownList} from "../../atoms/DropDownList";
import {DropdownContent} from "../../molecules/DropDownContent";
import {Toast} from "../../atoms/Toast";
import {isMobileOnly} from "react-device-detect";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Global } from '@emotion/react';
import Skeleton from "@mui/material/Skeleton";



export const InboxDetailsAction:React.FC<InboxDetailsActionProps> = (props) => {
    let emailID:any = props.emailID;
    const detail:any = props.detail;
    let { actionRules, categories } = useContext(GlobalContext)['globalState'];
    const [openDialog, setOpenDialog] = useState(false);
    // const [openConfirm, setOpenConfirm] = useState(false);
    const [contentModal, setContentModal] = useState({title: '', content: '', action: ''});
    const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0});
    const [modalID, setModalID] = useState(0)
    const [changeStatus, setChangeStatus] = useState(props.status)
    // @ts-ignore
    const zoneID:any = localStorage.getItem('currentZone');
    
    useEffect(()=> {
        setChangeStatus(props.status)
    }, [props.status])
    
    const handleChangeAndUpdateInbox = (key: string, value:string) => {
        const row = rowParams(emailID, {[key]: value})
        updateInboxMessage(row).then((data) => {
            props.handleEmailChanged()
        }).catch(() => {})
    }
    
    const setDropdownListData = (dataList: object, handleOnClick: any, color?: string, parent?: string) => {
        let dtList: any = [];
        Object.keys(dataList).forEach((key) => {
            dtList.push({
                parent: parent,
                text: dataList[key]['label'] || dataList[key]['text'],
                value: dataList[key]['id'] || dataList[key]['value'] || key,
                customStyle: {
                    hoverBg: dataList[key]['hoverBg'],
                    hoverColor: dataList[key]['hoverColor'],
                    borderRadius: dataList[key]['borderRadius'] || 4
                },
                style: {
                    color: color || dataList[key]['selectColor'] || dataList[key]['color'],
                    background: dataList[key]['background'],
                    fontWeight: 600,
                },
                onClick: handleOnClick
            })
        });
        return dtList;
    }
    
    const userLocalData = localStorage.getItem('userData');
    let userData:any = [];
    if(userLocalData) {
        userData = JSON.parse(userLocalData);
    }
    
    const rowParams = (emailID:any,  data: object ) => {
        return Object.assign({
            "emailId": emailID,
            "zoneId": userData.zoneId.toString(),
            "adminId": userData.userId.toString(),
            "actionType": userData.role
        }, data);
    }
    
    const renderDropdownList = (title: string, dataList: object, displayVariant: any, value: any) => {
        return <Box
          className={'dropdownlist-inbox-action'}
          sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: 2,
              height: "100%"
          }}
        >
            <Typography variant={'body2'} sx={{fontWeight: 'bold'}}>{title}</Typography>
            <DropDownSelect variant={displayVariant} value={value} dataset={dataList} title={title} is_archived={detail.is_archive}/>
        </Box>
    }
    
    const handleClickOpen = (action?:any) => {
        const content:any = {
            title: 'Send email',
            content: '',
            action: action
        }
        setOpenDialog(true);
        setModalID(modalID + 1)
        setContentModal(content)
    };
    
    const handleClose = () => {
        setOpenDialog(false);
    };
    
    /**
     * handleRunActionRule
     * Action controller
     * @param rule
     */
    const handleRunActionRule = (rule:any) => {
        alert('Click ' + rule + ' on ' + emailID);
    }
    actionRules.forEach((item:any) => {
        item.onClick = handleRunActionRule
    })
    
    const defaultParams: any = {
        subject: detail.subject,
        from: detail.email,
        fromName: '',
        content: '',
        base64Data: '',
        recipients: [],
        reportedBy: detail.reportedBy
    }
    
    const drawerBleeding = 0;
    const [isActionShow, setActionShow] = React.useState(false);
    
    const toggleDrawer = (newOpen: boolean) => () => {
        setActionShow(newOpen);
    };
    
    const [paramSendMail, setParamSendMail] = useState(defaultParams)
    
    let prioritiesList:any = setDropdownListData(CONSTANTS.PRIORITIES, handleChangeAndUpdateInbox, '#fff', 'priority');
    let statusesList:any = setDropdownListData(CONSTANTS.STATUS, handleChangeAndUpdateInbox, undefined, 'status');
    let categoryList:any = setDropdownListData(categories, handleChangeAndUpdateInbox, '#011111', 'category');
    
    const sendEmail:any = (paramSendMail:object) => {
        const editorValue = document.getElementsByClassName("jodit-wysiwyg");
        const changeStatusParams = {
            emailId: emailID,
            status: 'Resolved',
            priority: props.priority,
            category: props.category
        }
        if(editorValue)
            paramSendMail['content'] = editorValue[0].innerHTML;
        paramSendMail['attMail'] = {'original_email.eml': props.originalEmail};
        paramSendMail['emailId'] = emailID;
        paramSendMail['zoneId'] = zoneID.toString();
        setOpenDialog(false);
        setActionShow(false);
        return sendMessageEmail(paramSendMail).then((result:any) => {
            setToastValue({type: 'success', message: result.data, isOpen: true, toastId: modalID})
            if(paramSendMail['statusToResolved']){
                return updateTriageInboxes(changeStatusParams).then((result:any) => {
                    setChangeStatus('Resolved')
                });
            }
        });
    };

    const is_archived = window.location.pathname.split('/archived/').length === 2
    
    const actionOtherData:any = is_archived ? {
        "Delete": DELETE,
    } :{
        "SendMail": SEND_EMAIL,
        // "Purge": PURGE_OPTION,
        "Archive": ARCHIVE,
        "Delete": DELETE,
    }
    
    const menuData:any = [{
        email_id: emailID,
    }];
    
    const validatePopupSendMail = () => {
        if (!(paramSendMail['to'].length === 0)){
            sendEmail(paramSendMail)
        } else {
            setModalID(modalID + 1)
            setToastValue({type: 'error', message: 'Please input "To" field', isOpen: true, toastId: modalID})
        }
    }

    return (
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Stack spacing={2}>
              <Stack
                style={{backgroundColor: '#f2f3f3'}}
                id='action-mail'>
                  {renderDropdownList('Priority:', prioritiesList, 'caption', props.priority)}
                  {isMobileOnly ? "" : <hr/>}
                  {renderDropdownList('Status:', statusesList, 'body2', changeStatus)}
                  {isMobileOnly ? "" : <hr/>}
                  {renderDropdownList('Category:', categoryList, 'body2', props.category)}
                  {isMobileOnly ? "" : <Divider light />}
              </Stack>
              {isMobileOnly ? <Box
                sx={{
                    boxShadow: '-4px -4px 16px rgba(0, 0, 0, 0.06);',
                    backgroundColor: '#ffffff',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    padding: '16px',
                    zIndex: 1
                    
                }}
              >
                  <Box sx={{ textAlign: 'center', pt: 1 }} className={'action-mobile'}>
                      <Button
                        sx={{
                            height: 48,
                            backgroundColor: '#345C8B',
                            justifyContent: 'space-between',
                            color: '#ffffff',
                            "&:hover": {
                                color: '#345C8B',
                            },
                        }}
                        fullWidth={true}
                        onClick={toggleDrawer(true)}
                        endIcon={<ArrowDropUpIcon fill={'#ffffff'}/>}
                      > Action </Button>
                  </Box>
              </Box> : ""}
              {isMobileOnly ? <Box>
                  <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `calc(50% - ${drawerBleeding}px)`,
                            overflow: 'visible',
                            borderTopRightRadius: '16px',
                            borderTopLeftRadius: '16px',
                        },
                    }}
                  />
                  {/*// @ts-ignore*/}
                  <SwipeableDrawer
                    anchor="bottom"
                    open={isActionShow}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        position: 'relative',
                    }}
                  >
                      <Box sx={{
                          padding: '16px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          borderBottom: '1px solid #E0E0E0'
                      }}>
                          <Typography sx={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              color: '#193051',
                              fontWeight: 600
                          }}>
                              Action
                          </Typography>
                          <CloseIcon onClick={toggleDrawer(false)}/>
                      </Box>
                      <DropdownContent menuData={menuData}
                                       actionOtherData={actionOtherData}
                                       handleOpenSendMail={handleClickOpen}
                                       handleDeleteMail={props.handleDelete}
                                       handleArchiveMail={props.handleArchiveMail}
                                       handlePurgeMail={props.handlePurgeMail}
                      />
                  
                  </SwipeableDrawer>
              </Box> : <Stack spacing={1}>
                  <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0 0 4px 0',
                    }}>
                      <DropDownList
                        variantDropdown='actions-dropdown'
                        selectedTitle={'ACTIONS'}
                        children={<DropdownContent menuData={menuData}
                                                   actionOtherData={actionOtherData}
                                                   handleOpenSendMail={handleClickOpen}
                                                   handleDeleteMail={props.handleDelete}
                                                   handleArchiveMail={props.handleArchiveMail}
                                                   handlePurgeMail={props.handlePurgeMail}
                        
                        />}
                      />
                  </Box>
              </Stack>}
          
          </Stack>
          
          <Modal idModal='modal-send-mail'
                 variant='send-mail'
                 disableEnforceFocus={true}
                 fullScreen={isMobileOnly ? true : false}
                 openDialog={openDialog}
                 title={contentModal.title}
                 actionAgree={validatePopupSendMail}
                 actionCancel={handleClose}
                 textAgree={'Send'}
                 textCancel={'Cancel'}
                 onClose={handleClose}
                 handleOnChange={(e:any) => setParamSendMail(() => {
                     return  ({
                         ...paramSendMail,
                         'statusToResolved': e.target.checked,
                     })
                 })}
          >
              <SendMailModal defaultParams={defaultParams} paramSendMail={paramSendMail} setParamSendMail={setParamSendMail} scanResult={props.scanResult}/>
          </Modal>
          <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
      </Box>
    
    )
}




