import React, {Fragment, useContext, useState} from "react";
import {GlobalContext} from "../context/GlobalState";

export default function ChangeWithZone<T>(Component: React.ComponentType<T>) {
  const CE: React.FC<T> = (props) => {
    const { globalState } = useContext(GlobalContext)
    const [currentZone, setCurrentZone] = useState(globalState.currentZone)
    
    if (currentZone !== globalState.currentZone) {
      setCurrentZone(globalState.currentZone)
    }
    
    return (
      <Fragment key={currentZone}>
        {/*//@ts-ignore*/}
        {<Component currentZone {...props} />}
      </Fragment>
    )
  }
  return CE
}
