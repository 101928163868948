import React, {useContext, useEffect, useState} from 'react';
import  { useNavigate, useLocation } from 'react-router-dom';
import {LeftNavButtonProps} from './LeftNavButton.props';
import './LeftNavButton.styles.css';
import {Button} from "../../atoms/Button";
import {Image} from "../../atoms/Image";
import {Images} from "../../../configs/images";
import {Text} from "../../atoms/Text"
import { ReactComponent as MailIcon } from "../../../assets/images/mail.svg";
import { ReactComponent as DashboardIcon } from "../../../assets/images/dashboard.svg";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import {isMobileOnly} from "react-device-detect";
import {GlobalContext} from "../../../context/GlobalState";
import ChangeWithZone from "../../../hoc/ChangeWithZone";
import {AccordionButton} from "../AccordionButton";


const arrSvg = [
  // <DashboardIcon className={'btnNode'}/>,
  <MailIcon className={'btnNode'}/>,
  // <BuildIcon className={'btnNode'}/>,
  // <PieIcon className={'btnNode'}/>,
  // <ReportIcon className={'btnNode'}/>
]


function findById(array:any, id:any) {
  for (const item of array) {
    if (item.id === id) return item;
    if (item.children?.length) {
      const innerResult:any = findById(item.children, id);
      if (innerResult) return innerResult
    }
  }
}

const LeftNavButtonRoot: React.FC<LeftNavButtonProps> = ({
  leftNavBtnArr,
  isOpen = false,
  setOpenNav
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  
  let { globalState } = useContext(GlobalContext);
  
  let [zoneTree, setZoneTree] = useState(globalState.zones || [])
  let [zoneName, setZoneName] = useState('')
  
  useEffect(() => {
    const oldZoneId = localStorage.getItem('currentZone') || '0'
    let currentTree = findById(zoneTree, parseInt(oldZoneId))
    if (currentTree) {
      setZoneName(currentTree.name)
    } else {
      setZoneName("")
    }
  }, [zoneTree])

  
  const btnNav = (btn:any, index: number, hasTitle: boolean, isOpen: boolean, hasChild?: boolean) =>
    <div key={index}>
      <Button
        key={index}
        margin='0 0 4px 12px'
        color='#677070'
        variant={'left-nav-btn'}
        padding={'0 11px'}
        border='none' backgroundColor='transparent'
        onClick={(e:any) => {
            e.preventDefault();
            navigate(btn.href)
            if (setOpenNav && isMobileOnly) {
              setOpenNav()
            }
          }
        }
        component={hasChild ? null : arrSvg[index]}
        title={btn.name}
        actived={hanldeActiveBtn(btn.href, location.pathname)}
        borderRadius='4px'
        height={40}
        titleMargin={hasChild ? '0 0 0 32px' : '0 0 0 16px'}
        width={hasTitle && !isOpen ? 'calc(100% - 12px)': 'calc(100% - 24px)'}
        // tooltipComponent = {
        //   isMobileOnly ? "" :  isOpen && <div className='tooltip'>
        //     <div className='tooltip-inner'>{btn.name}</div>
        //     <span className='arrow'></span>
        //   </div>
        // }
      />

    </div>
  const hanldeActiveBtn = (path:any, location:any) => {
    // console.log(path, location, isArchivedEmail, location.indexOf(path))
    if (location === '/' && path === '/dashboard') {
      return true
    }  else if(location !== '/') {
      return location.indexOf(path) > -1
    }
  }

  const activedLeftMenu:any = location.pathname === '/' || location.pathname === '/archived' || location.pathname === '/inbox' || location.pathname.split('/inbox/').length === 2 || location.pathname.split('/archived/').length === 2

  return (
      <div className={`left-nav fixed ${isOpen? 'opened' : ''}`}>
        <div className={"left-nav-content"}>
          <Image srcImg={Images.AvatarLogo}
                 height={40}
                 width={40}
                 borderRadius={'50%'}
          />
          <Text children={zoneName}
                margin={"0 0 0 12px"}
                color={"#193051"}
                fontWeight={700}
          />
          { isMobileOnly ? <IconButton
            aria-label="close"
            onClick={setOpenNav}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: '#000'
            }}
          >
            <CloseIcon />
          </IconButton> : null}
        </div>
        <div className="button-section-group ">
          {
            leftNavBtnArr?.map(( btn:any, index) =>
              <div key={index}>
                {btn.child ? <AccordionButton idComponent={'left-menu'}
                                              title={btn} icon={arrSvg[index]}
                                              actived={activedLeftMenu ? true : false}
                                              children={btn.child.map((btnChild:any, indexChild:any) => btnNav(btnChild, indexChild, true, isOpen, true))}/>
                  : btnNav(btn, index, true, isOpen)}
              </div>
            )
          }
        </div>
        <div className={`left-nav-bottom ${isOpen ? 'opened' : ''} `}>
          <div className='company-logo'>
            <Image srcImg={Images.LogoOnly} width={24} height={26} margin='0 0 0 16px'/>
            <Image cssClass='dark-logo-text' srcImg={Images.DarkLogo} height={28} width={64} margin='0 0 0 6px'/>
          </div>
          {isMobileOnly ? <div className='company-name'>
            <span style={{color : "#011111"}} className='copyright-sign'>&#169; 2022 Phriendly Triage Centre.</span>
          </div> : null}
        </div>
      </div>
  );
};

export const LeftNavButton = ChangeWithZone(LeftNavButtonRoot);