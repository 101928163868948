import {Images} from "../configs/images";
export const CONSTANTS = {
  SERVICE_TYPES: {
    INBOX: "inboxes",
    EMAIL: 'email',
    DASHBOARD: 'dashboard',
    REFERENCE_DATA: 'referenceData',
    SUBSCRIPTIONS: 'subscriptions'
  },
  'ROUTER': [
    // {
    //   name: 'Dashboard',
    //   href: '/dashboard',
    //   linkIcon: Images.DashboardIcon
    // },
    {
      name: 'Inbox',
      href: '/inbox',
      // linkIcon: Images.MailIcon,
      child: [
        {
          name: 'Archived',
          href: '/archived',
          // linkIcon: Images.MailIcon,
        }
      ]
    },
    // {
    //   name: 'Actions',
    //   href: '/actions',
    //   linkIcon: Images.BuildIcon
    // },
    // {
    //   name: 'Reports',
    //   href: '/reports',
    //   linkIcon: Images.ReportIcon
    // },
    // {
    //   name: 'Settings',
    //   href: '/reports',
    //   linkIcon: Images.SettingIcon
    // }
  ],
  "PRIORITIES": {
    "High": {
      'linkIcon': '',
      'color': '#ED1C24',
      'background': '#ED1C24',
      "hoverBg": "#B20007",
      "label": 'High',
      "id": 'High',
      "borderRadius": 100
    },
    "Medium": {
      'linkIcon': '',
      'color':'#FCAB10',
      'background':'#FCAB10',
      "hoverBg": "#D88E00",
      "label": 'Medium',
      "id": 'Medium',
      "borderRadius": 100
    },
    "Low": {
      linkIcon: '',
      background: '#03A9F5',
      "hoverBg": "#007AB2",
      color: '#03A9F5',
      "label": 'Low',
      "id": 'Low',
      "borderRadius": 100
    },

  },
  "CATEGORIES": {
    "Threat": "#ED1C24",
    "Clean": "#03A9F5",
    "Spam": "#B23292",
    "Unknown": "#808888"
  },
  "STATUS":{
    "Received": {
      "bgColor": "",
      "hoverColor": "",
      "color": "",
      "label": 'Received',
      "id": 'Received',
      'background': '#EDEDED',
      "hoverBg": "#EDEDED",
    },
    "InReview": {
      "bgColor": "43BD77",
      "hoverColor": "",
      "color": "",
      "selectColor": "",
      "label": 'In Review',
      "id": "In Review",
      'background': '#E1EFEF',
      "hoverBg": '#E1EFEF',
    },
    "Resolved": {
      "bgColor": "",
      "hoverColor": "",
      "color": "",
      "label": 'Resolved',
      "id": 'Resolved',
      "background": '#f2fad9',
      "hoverBg": "#f2fad9",
    },
  }
};
export const PRIORITY = "Priority";
export const CATEGORY = "Category";
export const SCAN_RESULT = "Scan Result";
export const STATUS = "Status";
export const SEND_EMAIL = "Send Email";
export const PURGE = "Purge";
export const PURGE_OPTION = "Remove emails in all users’ inbox in this zone";
export const PURGE_TITLE = "Remove emails in all user's inbox";
export const DELETE = "Delete";
export const ARCHIVE = "Archive";
export const MESSAGES_STATUS_CHANGED = "Selected messages’ status will be changed to";
export const MESSAGES_PRIORITY_CHANGED = "Selected messages’ priority will be changed to";
