import React from 'react';
import {PageHeaderProps} from "./PageHeader.props";
import {Breadcrumb} from "../../molecules/Breadcrumb";
import './PageHeader.styles.css';
import Typography from "@mui/material/Typography";

const titleStyle = {
    fontSize: '28px',
    margin: '10px 0px 0px',
    fontFamily: '"Righteous", serif'
}

export const PageHeader:React.FC<PageHeaderProps> = ({ pageTitle, breadCrumbData, setInboxEvent, children }) => {
    return (
        <div className={'page-content-header'}>
            {(window.location.pathname !== '/inbox' && window.location.pathname !== '/archived' && window.location.pathname !== '/') ? <div className={'page-content-breadcrumb'}>
              <Breadcrumb setInboxEvent={setInboxEvent} navigationLinks={breadCrumbData}></Breadcrumb>
            </div> : null}
            <div className={'page-content-title'}>
              <Typography variant={'h2'} color={'primary.dark'} className={'page-title'} {...titleStyle}>
                {pageTitle}
              </Typography>
              {children}
            </div>
        </div> 
    )
}
