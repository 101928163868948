import React from 'react';
import './DropDownFilter.styles.css';
import {DropDownFilterProps} from './DropDownFilter.props';
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {Checkbox, ListItem} from "@mui/material";
import {Text} from '../../atoms/Text';
import FormControlLabel from '@mui/material/FormControlLabel';

import FormControl from "@mui/material/FormControl";
import {Image} from "../../atoms/Image";
import {Images} from "../../../configs/images";

export const DropDownFilter: React.FC<DropDownFilterProps> = ({
    arrList,
    label,
    type,
    width= 180,
    height= 40,
    onSelectItem,
    searchFieldPlaceholder= 'Search...',
    setSearchQuery,
    selectedItems,
    clearFilerSelection,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChange = (event: any, type: any) => {
    const {target: { value, name = type }} = event;
    onSelectItem(value, name);
  };

  const clearSelectedCheckbox = (type: string) => {
    clearFilerSelection(type);
    onSelectItem([], type);
    setAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const checkSelected = selectedItems[type] && selectedItems[type].length;
  const colons = checkSelected > 0 ? ':' : '';
  const formWidth = checkSelected > 0 ? 'auto' : width;

  return (
    <FormControl className={checkSelected ? 'has-selected' : ''} sx={{ m: 1, width: formWidth}}>
      <span className='title-content'>{label + colons}</span>
      <Select
        labelId={type + "-checkbox-label"}
        id={type + "-multiple-checkbox"}
        onOpen={(e:any) => handleOpen(e)}
        onClose={handleClose}
        open={open}
        autoWidth={true}
        multiple
        // @ts-ignore
        value={selectedItems[type] || []}
        onChange={(e:any) => { handleChange(e, type)} }
        renderValue={(selected) => <span>{selected[0]} {selected.length > 1 && <span className='selected-total'>+{selected.length - 1}</span>}</span> }
        MenuProps={{
          anchorEl: anchorEl,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
      >
        {
          arrList.length > 7 && <ListItem className='search-input-item'>
            <TextField
              fullWidth
              placeholder={searchFieldPlaceholder || "Search"}
              onChange={e => {
                if (setSearchQuery) {
                  setSearchQuery(e.target.value, arrList);
                }
              }}
              onKeyDown={e => {
                e.stopPropagation();
              }}
            />
            <Image srcImg={Images.SearchInFilter}
                   cssClass={`search-icon`}
                   height={18}
                   width={18}
                   borderRadius={"0"}
                   margin={'0'}
            />
          </ListItem>
        }
        {arrList.map((item: any) => {
          return <MenuItem key={item.id} value={item.content} className='filter-item'>
            <FormControlLabel control={
              <Checkbox
                // @ts-ignore
                className={'checkbox_' + type}
                id={type+'-'+item.id}
                checked={selectedItems[type] && selectedItems[type].includes(item.content)} />
              } label={item.content}
            />
          </MenuItem>
        }
        )}
        {
          checkSelected > 0 && <ListItem className='clear-selected' onClick={() => clearSelectedCheckbox(type)}>
            <Text color='#467599' children='Clear selection' fontWeight={600} fontSize={14} />
          </ListItem>
        }
      </Select>
    </FormControl>

  );
};
