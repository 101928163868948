import React, {useState} from 'react';
import {StyledMenuProps} from './StyledMenu.props';
import './StyledMenu.styles.css';
import {Images} from '../../../configs/images';
import {Image} from "../../atoms/Image";
import {alpha, styled} from "@mui/material/styles";
import Menu, {MenuProps} from "@mui/material/Menu";
import {Modal} from "../../atoms/Modal";
import {ARCHIVE, DELETE, PURGE_OPTION, SEND_EMAIL} from "../../../constants/contants";
import {DropdownContent} from "../DropDownContent";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 120,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const ActionsColumn: React.FC<StyledMenuProps> = ({
  menuData,
  showActionsDropdown,
  hideActionsDropdown,
  open,
  anchorEl,
  handleDelete,
  handleArchive,
  handleSendMail,
  handlePurgeMail,
  actionOtherData,
  rowSelected
}) => {
  // @ts-ignore
  return menuData && <div className='actions-customize' style={{height: menuData.length !== 0 ? menuData.length * 52 - 2 : 0}}>
    {Object.keys(menuData).map((index:any) => {
      return <div key={index}><div className="action-dropdown-list">
        <Image cssClass={`image-style image-action`}
          // @ts-ignore
           data-emailid={menuData && menuData[index].email_id}
           srcImg={Images.MoreVertical}
           width="30px"
           margin="0"
           onClick={showActionsDropdown}
        />
      </div>
        <StyledMenu
          id="actions-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'actions-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={hideActionsDropdown}
        >
          <DropdownContent menuData={rowSelected}
                           actionOtherData={actionOtherData}
                           handleOpenSendMail={handleSendMail}
                           handleDeleteMail={handleDelete}
                           handleArchiveMail={handleArchive}
                           handlePurgeMail={handlePurgeMail}/>
        </StyledMenu>
      </div>
    })}
  </div>
};
