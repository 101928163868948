import React, {useState} from 'react';
import {FooterProps} from './Footer.props';
import './Footer.styles.css';


export const Footer: React.FC<FooterProps> = ({
}) => {
  return (
    <footer>
      <span className='copyright-sign'>&#169; 2022 Phriendly Phishing Email Triage.</span>
    </footer>
  );
};
