import React from 'react';
import {MailUrlsViewerProps} from "./MailUrlsViewer.props";
import Iframe from "../IFrame/IFrame";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {useTheme} from "@mui/material/styles";


export const MailUrlsViewer: React.FC<MailUrlsViewerProps> = ({
    content
}) => {
  const urlList = content
  const theme = useTheme();
  return (
    <Stack className={'mail-urls-viewer'} spacing={2}>
      { content.length === 0 ? <p className={"nodata-content"}> No URLs were contained in this email</p> : urlList.map((item:any, index:any) => {
        return (<Box sx={{padding: theme.spacing(2), background: "#F8F8F8"}} key={index}>
          <Typography sx={{wordWrap: 'break-word', wordBreak: 'break-all', fontWeight: 500}}  variant={'body2'}>{item.value}</Typography>
        </Box>)
      })}
    </Stack>
  );
};
