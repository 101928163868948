import React, {useEffect, useRef, useState} from 'react';
import {TagInputProps} from './TagInput.props';
import './TagInput.styles.css';
import {Box, Checkbox, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import {Clear} from "@mui/icons-material";
import validator from 'validator';

const Tags:any = ({ data, handleDelete }:any) => {
    return (
        <Box
            sx={{
                background: "#E7EBF0",
                height: "100%",
                display: "flex",
                padding: "6px 12px 6px 16px",
                margin: "0 0.5rem 0 0",
                justifyContent: "center",
                alignContent: "center",
                borderRadius: "24px",
                color: "#011111",
            }}
        >
            <Stack direction='row' gap={1}>
                <Typography>{data}</Typography>
                <Clear
                    sx={{ cursor: "pointer", width: "17px"}}
                    onClick={() => {
                        handleDelete(data);
                    }}
                />
            </Stack>
        </Box>
    );
};

export const TagInput: React.FC<TagInputProps> = ({
    handleChange,
    type,
    paramSendMail,
    setParamSendMail,
    checkboxInput
}) => {
    const [tags, SetTags] = useState<any>([]);
    const [checked, setChecked] = useState(false)
    const tagRef:any = useRef();
    const [invalidEmail, setInvalidEmail] = useState(false)
    const handleDelete = (value:any) => {
        const newtags = tags.filter((val:any) => val !== value);
        SetTags(newtags);
        if(value === paramSendMail.reportedBy){
            setChecked(!checked)
        }
    };

    const addTag = (lowercaseEmail:any) => {
        SetTags([...tags, lowercaseEmail]);
        setInvalidEmail(false);
        let item:any;
        if (type === 'to' || type === 'cc'){
            item = [...tags, lowercaseEmail]
        }
        setParamSendMail(() => {
            return  ({
                ...paramSendMail,
                [type]: item,
            })
        })
    }

    const handleOnSubmitTag = (e:any) => {
        e.preventDefault();
        const lowercaseEmail = tagRef.current.value.toLowerCase().trim()
        const newTags = tags.filter((val:any) => val === lowercaseEmail);
        if (validator.isEmail(lowercaseEmail)){
            if(newTags.length === 0){
                addTag(lowercaseEmail);
                tagRef.current.value = "";
            } else {
                setInvalidEmail(false);
                tagRef.current.value = "";
            }
        } else {
            tagRef.current.value = tagRef.current.value;
            setInvalidEmail(true);
        }
    };

    // const handleOnChange = (e:any, type:any) => {
    //     let item:any = e.target.value;
    //     if (type === 'to' || type === 'cc'){
    //         item = [...tags, e.target.value]
    //     }
    //     setParamSendMail(() => {
    //         return  ({
    //             ...paramSendMail,
    //             [type]: item,
    //         })
    //     })
    // }

    const handleAddTagReporter = (e:any) => {
        const status = e.target.checked
        if(status){
            SetTags([...tags, paramSendMail.reportedBy]);
            setParamSendMail(() => {
                return  ({
                    ...paramSendMail,
                    [type]: [...tags, paramSendMail.reportedBy],
                })
            })
            setChecked(true)
        } else {
            handleDelete(paramSendMail.reportedBy)
            setChecked(!checked)
        }
    }

    return (
        <>
            <Box sx={{flexGrow: 1, marginTop: '-12px'}} id={'outline-tag-textfield'}>
                <form onSubmit={handleOnSubmitTag}>
                    <TextField
                        inputRef={tagRef}
                        fullWidth
                        variant='outlined'
                        size='small'
                        sx={{margin: "1rem 0"}}
                        placeholder="Input email and Press Enter"
                        InputProps={{
                            startAdornment: (
                                <Box sx={{margin: "0 0.2rem 0 0", display: "flex", flexWrap: "wrap"}} className={'tag-container'}>
                                    {tags.map((data: any, index: any) => {
                                        return (
                                            <Tags data={data} handleDelete={handleDelete} key={index}/>
                                        );
                                    })}
                                </Box>
                            ),
                        }}
                        error={invalidEmail}
                        helperText="Invalid email address"
                        // onChange={(e:any) => handleOnChange(e, type)}
                    />
                </form>
            </Box>
            {checkboxInput ? <FormControlLabel sx={{marginTop: '-15px'}} control={<Checkbox checked={checked} onChange={(e:any) => handleAddTagReporter(e)}/>} label="Include original reporter"/> : null}
        </>
    );
};
