import React from 'react';
import {MailAttachmentViewerProps} from "./MailAttachmentViewer.props";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import DownloadIcon from '@mui/icons-material/Download';
import './MailAttachments.styles.css';
import {ControlDisplay} from "../ControlDisplay";

const bytesToSize = function(bytes:any, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const MailAttachmentViewer: React.FC<MailAttachmentViewerProps> = ({
    content
}) => {
    const theme = useTheme()
  const fileList = content
  const downloadFile = function (index:any) {
      let item = fileList[index]
      var element = document.createElement('a');
      element.setAttribute('href', 'data:' + item.mail_content_type + ';base64,' + item.payload);
      element.setAttribute('download', item.filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
  }

  return (
    <Stack spacing={2}>
      
      { content.length === 0 ?  <p className={"nodata-content"}> No attachments were contained in this email</p> : fileList.map((item:any, index:any) => {
          let base64str = item.payload
          let decoded = item.binary ? atob(base64str) : base64str;
          const fileSize = bytesToSize(decoded.length)
          return (<Box className='attachment-download-wrapper' sx={{padding: theme.spacing(2), background: "#F8F8F8", maxWidth: 500}} key={index}>
           <Stack spacing={1}>
                  <div className={'attachment-header'}>
                      <FolderZipIcon sx={{marginRight: theme.spacing(1)}}/>
                      <Typography sx={{wordWrap: 'break-word', wordBreak: 'break-all', fontWeight: 500}} >{item.filename}</Typography>
                  </div>
                  <div className={'attachment-body'}>
                      <ControlDisplay label={'File size: '} labelAlign={'left'} labelColor={theme.palette.black.light} contentColor={theme.palette.black.main} content={fileSize} labelWidth='70px'/>
                  </div>
              </Stack>
              {/*<button className={"btn-attachment-download"} onClick={() => downloadFile(index)}>*/}
              {/*    <Tooltip title="Download" placement="top">*/}
              {/*        <DownloadIcon sx={{fontSize: 12, color: theme.palette.black_darker.light}} />*/}
              {/*    </Tooltip>*/}
              
              {/*</button>*/}
            
          </Box>)
      })}
    </Stack>
  );
};
