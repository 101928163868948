import React from 'react';

export default (state:any, action:any) => {
   switch(action.type) {
     case 'UPDATE_SELECTED_EMAIL': {
           return {
               ...state,
                selectedEmail: action.payload
           }
       }
     case 'UPDATE_SELECTED_ZONE_ID': {
       return {
         ...state,
         currentZone: action.payload
       }
     }
   case 'UPDATE_ZONE_LIST': {
       return {
           ...state,
           zones: action.payload
       }
   }
       default:
           return state;
   }
}
