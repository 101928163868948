import React, {useContext, useEffect, useState} from 'react';
import {HeaderProps} from './Header.props';
import './Header.styles.css';
import {Image} from '../../atoms/Image';
import {Images} from '../../../configs/images';
import {DropDownList} from '../../atoms/DropDownList';
import { ReactComponent as MoreVert } from "../../../assets/images/more_vert.svg";
import {ZoneSwitcher} from "../ZoneSwitcher";
import { GlobalContext } from "../../../context/GlobalState";
import { getUserData } from '../../../api/ph2';
import {isMobileOnly} from "react-device-detect";
import LogoutIcon from '@mui/icons-material/Logout';

const findById:any = function (array:any, id:any) {
  for (const item of array) {
    if (item.id.toString() === id.toString()) return item;
    if (item.children?.length) {
      const innerResult = findById(item.children, id);
      if (innerResult) return innerResult;
    }
  }
}
export const Header: React.FC<HeaderProps> = ({
  onClick,
}) => {
  const { globalState, globalDispatch, reloadDispatcher } = useContext(GlobalContext);
  const handleLogout = () => {
    //@ts-ignores
    const userLocalData = JSON.parse(localStorage.getItem('userData'));
  
    sessionStorage.setItem("isTimeout", "false");
    sessionStorage.setItem("isLogout", userLocalData['role']);
    localStorage.clear();
    window.location.href = '/logout';
    
    
  }
  const userData = JSON.parse(localStorage.getItem('userData') || "{}")
  let fullName = '';
  if(userData) {
    fullName = userData.fullName;
  }

  const [zoneTree, setZoneTree] = useState(globalState.zones || [])

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken') || ''
    getUserData(accessToken).then((response:any) => {
      globalDispatch({
        type: 'UPDATE_ZONE_LIST',
        payload: response.data.zones
      })
      
      let activeZone = localStorage.getItem('currentZone') || userData['zoneId']
      let currentZone = findById(response.data.zones, activeZone)
      
      if(!currentZone) {
        activeZone = response.data.zones[0]?.id
        localStorage.setItem('currentZone', activeZone)
      }
      
      globalDispatch({
        type: 'UPDATE_SELECTED_ZONE_ID',
        payload: activeZone
      })
  
      localStorage.setItem('encoded_zones', response.data.encoded_zones)
      localStorage.setItem('currentZone', activeZone)
      setZoneTree(response.data.zones)
      reloadDispatcher(activeZone)
    })
  }, [])

  const userContent = <div className='item'>
    <ul>
      {isMobileOnly ? <li style={{padding: "16px"}} key='fullname' className='full-name'>{fullName}</li> : null}
      <li style={{padding: "16px", display: 'flex', alignItems: 'center', justifyContent: "space-between"}} key='logout' onClick={handleLogout}>Log Out {
        isMobileOnly ? <LogoutIcon/> : ""}</li>
    </ul>
  </div>

  return (
    <header className='fixed'>
      <div className='header-title'>
          <Image width={24} srcImg={Images.HamburgerMenu} onClick={onClick}/>
          <ZoneSwitcher dataSource={zoneTree} />
      </div>
      <DropDownList
        backgroundColor={'transparent'}
        variantDropdown='icon-dropdown-header'
        labelChild={isMobileOnly ? <MoreVert/> : null}
        selectedTitle={!isMobileOnly ? fullName : null}
        children={userContent}
      />
      {/*<UserProfile onClick={handleLogout}/>*/}
    </header>
  );
};
