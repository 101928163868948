import {httpClient, HttpMethod} from "./httpClient";
import axios from "axios";
import appConfig from "../configs/appConfig.json";


const basePh2URL = process.env.REACT_APP_PH2_USER_INFO || ''
// appConfig.PH2Authorize.userInfo

export const getUserData = (token:string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const unInterceptedAxiosInstance = axios.create();
    const data = unInterceptedAxiosInstance({
          method: 'GET',
          url: basePh2URL,
          headers: {
            Authorization: `Bearer ${token}`,
            hostName: process.env.REACT_APP_HOSTNAME || ''
          }
        })
    data.then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
  })
};
