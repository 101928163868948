import React, {useEffect, useState} from 'react';
import './Logout.css';
import { Images} from '../../configs/images';
import {Image} from '../../components/atoms/Image';
import {Text} from "../../components/atoms/Text";
import {Link} from "../../components/atoms/Link";
import { ReactComponent as LogoutIcon } from "../../assets/images/entypo_log-out.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock.svg";
import appConfig from '../../configs/appConfig.json';
import {isMobileOnly, isMobile} from "react-device-detect";
import {useLocation} from "react-router-dom";

export const Logout:  React.FC = () => {
  
  let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL;
  const isLogout = sessionStorage.getItem('isLogout');
  const isTimeout = sessionStorage.getItem('isTimeout');
  
  let checkTimeout =  false
  if (isLogout==='company_admin') {
    loginLink += "/company_admin";
  } else {
    loginLink += "/admin";
  }
  
  if (isTimeout==='true') {
    checkTimeout = true
  }
  
  const [isAllow, setIsAllow] = useState(false)
  useEffect(() => {
    
    const isLogout = sessionStorage.getItem('isLogout');
    
    if (isLogout !== null) {
      setIsAllow(true)
    } else {
      setIsAllow(false)
    }
  },[])
  
  const location = useLocation();
  
  
  let marLeftLogo = '40px 0 0 44px'
  let marTitle = '40px 0 24px 0'
  
  let fontTitle = 24
  let fontText = 16
  
  let fontLogo = 200
  let fontIcon = 32
  
  let fontLogoutoBg = 88
  
  let padContent = '0 26px'
  
  if (isMobile) {
    
    marLeftLogo = '32px 0 0 36px'
    marTitle = '32px 0 16px 0'
    
    fontTitle = 20
    fontText = 14
    
    fontLogo = 160
    fontIcon = 26
    
    padContent = '0 20px'
    
    fontLogoutoBg = 78
  }
  
  if (isMobileOnly) {
    
    marLeftLogo = '32px 0 0 36px'
    marTitle = '32px 0 16px 0'
    
    fontTitle = 18
    fontText = 12
    
    fontLogo = 120
    fontIcon = 20
    
    fontLogoutoBg = 68
    
    padContent = '0 14px'
  }

  const iconDeny = <LockIcon className={'logout-icon'} width={fontIcon} height={fontIcon}/>
  const iconAllow = <LogoutIcon className={'logout-icon'} width={fontIcon} height={fontIcon}/>
  
  const titleAllow = checkTimeout ?  "Your login session is timeout.":"You have successfully logged out."
  const titleDeny = "Sorry, you don’t have permission to access this page."
  
  const contentAllow = 'To log back in again, return to'
  const contentDeny = <span>Please contact your <span style={{fontWeight: 700}}>Phriendly Phishing Customer Success Manager</span></span>
  
  const lastContentAllow = <span><Link cssClass={'link-logout'} fontSize={fontText} url={loginLink} color={"#467599"}>Phriendly Phishing</Link>.</span>
  const lastContentDeny = 'if you should have access to this feature.'
  

  
  return (
    <div className={'logout-wrapper'}>
      <Image cssClass={'logo-logout-left'} width={fontLogo} srcImg={Images.Logo} margin={marLeftLogo}/>
      <div className={'center-logout '} style={{padding: padContent }}>
        <div className={'logout-logo'} style={{width: fontLogoutoBg, height: fontLogoutoBg}}>
          {location.pathname!=='/logout' ? iconDeny: (isAllow? iconAllow : checkTimeout? iconAllow : iconDeny)}
        </div>
        <Text width={'100%'}
              fontWeight={700}
              fontSize={fontTitle}
              margin={marTitle}
              textAlign={'center'}
              children={location.pathname!=='/logout' ? titleDeny : (isAllow ? titleAllow : checkTimeout ? titleAllow : titleDeny)} color={'#193051'}/>
        <Text width={'100%'}
              color={'#212121'}
              fontSize={fontText}
              textAlign={'center'}
              children={location.pathname!=='/logout' ? contentDeny : (isAllow?contentAllow:checkTimeout ? contentAllow : contentDeny)} />
        <Text width={'100%'}
              color={'#212121'}
              fontSize={fontText}
              textAlign={'center'}
              children={
                location.pathname!=='/logout' ? lastContentDeny : (isAllow? lastContentAllow : checkTimeout ? lastContentAllow: lastContentDeny)
              }
        />
      
      </div>
    </div>
  )
}