import * as React from 'react';
import List, {ListProps} from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import Menu, {MenuProps} from '@mui/material/Menu';
import {alpha, styled} from "@mui/material/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import Skeleton from "@mui/material/Skeleton";
import {Image} from "../../atoms/Image";
import {Images} from "../../../configs/images";
import {isMobileOnly} from "react-device-detect";

const StyledList = styled((props: ListProps) => (
  <List
    {...props}
  />
))(({theme, ...props}) => ({
  '&.MuiList-root': {
    borderRadius: 6,
    width: 'auto',
    padding: 0,
    margin: 0,
    display: 'inline-block'
  },
  '&.MuiList-root .MuiListItem-root': {
    padding: '0 4px 0 12px',
    height: 20,
    width: 'auto',
    display: 'flex',
    fontSize: 12
  },
  '&.MuiList-root .MuiListItemText-root': {
    display: 'flex',
    margin: 0,
    fontWeight: 600,
    fontSize: 12
  },
  "&.Mui-selected": {
    backgroundColor: 'red'
  },
}));

const StyledListItem = styled((props: any) => (
  <ListItem
    {...props}
  />
))(({theme, ...props}) => ({
  '&.MuiListItem-root': {
    borderRadius: props.borderradius
  },
  '&.MuiListItem-root:hover': {
    backgroundColor: props.hoverbg || theme.palette.primary.active,
    color: props.hovercolor || theme.palette.secondary.active,
  },
  '&.MuiButtonBase': {
    disableRipple: true
  }
}));

export default function DropDownSelect(props: any) {
  const dataset: any = props.dataset || [];
  const is_archived: any = props.is_archived || false;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedValue, setSelectedValue] = React.useState(props.value);
  const [isLoading, setIsLoading] = React.useState(props.value === undefined);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setIsLoading(props.value === undefined)
    let objIndex = dataset.findIndex((obj:any) => {
      return obj.value == selectedValue;
    });
    if(objIndex < 0) {
      objIndex = 0;
    }
    setSelectedIndex(objIndex);
  })

  useEffect(() => {
    setSelectedValue(props.value)
  }, [props.value])

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    value: any
  ) => {
    setSelectedIndex(index);
    setSelectedValue(value);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {isLoading ? <Skeleton variant="text" height={15} width={80} sx={{fontSize: '1rem', width: '50%'}}/> : (
        <div>
          <StyledList className={props.title}>
            <StyledListItem
              disableRipple
              className={`${is_archived ? 'archived' : ''} ${props.title !== "Category:" ? "" : 'list-item-dropdown'}`}
              button
              id="lock-button"
              aria-haspopup="listbox"
              aria-controls="lock-menu"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClickListItem}
              color={props.color}
              hoverbg={dataset[selectedIndex].customStyle?.hoverBg ? dataset[selectedIndex].customStyle?.hoverBg : null}
              hovercolor={dataset[selectedIndex].customStyle?.hoverColor ? dataset[selectedIndex].customStyle?.hoverColor : null}
              borderradius={dataset[selectedIndex].customStyle?.borderRadius ? dataset[selectedIndex].customStyle?.borderRadius : 4}
              sx={dataset[selectedIndex].style}
            >

              <Typography sx={{fontWeight: 600}} variant={props.variant}>
                {dataset[selectedIndex].text}
              </Typography>
              {is_archived ? null : <ArrowDropDownIcon/> }
            </StyledListItem>
          </StyledList>
          {is_archived ? null : <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {dataset.map((item: any, index: any) => (
              <MenuItem
                value={item.value}
                key={index}
                selected={index == selectedIndex}
                onClick={(event) => {
                  item.onClick(item.parent, item.value);
                  handleMenuItemClick(event, index, item.value)
                }}
                style={{width: '120px', paddingRight: "16px"}}
              >
                {item.text}
                {index == selectedIndex ? <Image margin={"0 0 0 auto"} srcImg={Images.IconChecked} width={11} height={18}/> : ""}
              </MenuItem>
            ))}
          </Menu>}
        </div>)
      }
    </div>
  );
}
