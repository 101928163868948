import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import {Home} from './pages/Home';
import {Inbox} from './pages/Inbox';
import {Actions} from './pages/Actions';
import {Reports} from './pages/Reports';
import {Authen} from './pages/Authen/Authen';
import {Logout} from './pages/Logout/Logout';
import {Callback} from './pages/Callback';
import {Header} from './components/organisms/Header';
import {Footer} from './components/organisms/Footer';
import {PageNotFound} from './pages/PageNotFound/PageNotFound';
import {LeftNavButton} from './components/molecules/LeftNavButton';
import {CONSTANTS} from './constants/contants';
import {GlobalContext, GlobalProvider} from "./context/GlobalState"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LightTheme from "./theme/light";
import {isMobileOnly} from "react-device-detect";
import IdleTimeOutHandler from './components/utils/IdleTimeOutHandler';

const queryClient = new QueryClient();
const theme = createTheme(LightTheme);

const App: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn')=='true')
  const [isArchivedEmail, setIsArchivedEmail] = useState(false)
  // const idToken = localStorage.getItem('idToken');
  //
  // const [zoneChange, setZoneChange] = useState("111");
  
  const checkLeftNav = () => {
    if(isOpen) {
      document?.getElementById('root')?.classList.remove('menu-opened')
    } else {
      document?.getElementById('root')?.classList.add('menu-opened')
    }
    
    setOpen(!isOpen);
    
  }
  
  const onZoneChanged = () => {
  
  }
  
  const[isActive, setIsActive]=useState(true)
  
  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router>
            {
              isLoggedIn ?
                <div>
                  <div className={'app-root'}>
                    <IdleTimeOutHandler onActive={()=>{setIsActive(true)}} onIdle={()=>{setIsActive(false)}}/>
                    <GlobalProvider onZoneChanged={onZoneChanged}>
                      <Header onClick={checkLeftNav}/>
                      <div className='left-content' >
                        <LeftNavButton setOpenNav={checkLeftNav} isOpen={isOpen} leftNavBtnArr={CONSTANTS.ROUTER} isArchivedEmail={isArchivedEmail}/>
                      </div>
                      
                      <div className={isOpen ? "right-content-opened": "right-content-close"}>
                        <div className='page-content'>
                          <div className='right-content'>
                            <Routes>
                              <Route path={"*"} element={<PageNotFound/>} />
                              <Route path='/' element={<Inbox isOpen={isOpen} setIsArchivedEmail={setIsArchivedEmail}/>} />
                              {/*<Route path='/dashboard' element={<Home />} />*/}
                              <Route path='/inbox' element={<Inbox isOpen={isOpen} setIsArchivedEmail={setIsArchivedEmail}/>} />
                              <Route path='/archived' element={<Inbox isOpen={isOpen} isArchived={true} setIsArchivedEmail={setIsArchivedEmail}/>} />
                              <Route path='/inbox/:id' element={<Inbox isOpen={isOpen} setIsArchivedEmail={setIsArchivedEmail}/> } />
                              <Route path='/archived/:id' element={<Inbox isOpen={isOpen} isArchived={true} setIsArchivedEmail={setIsArchivedEmail}/> } />
                              {/*<Route path='/actions' element={<Actions />} />*/}
                              {/*<Route path='/reports' element={<Reports />} />*/}
                              <Route path='/callback' element={<Callback />} />
                              <Route path='/logout' element={<Logout />} />
                            </Routes>
                          </div>
                        </div>
                      </div>
                    </GlobalProvider>
                    {!isMobileOnly ? <Footer/> : <></>}
                  </div>
                </div>: <Routes>
                  {/*<Route path='/dashboard' element={<Logout />} />*/}
                  <Route path='/inbox' element={<Logout />} />
                  <Route path='/inbox/:id' element={<Logout />} />
                  <Route path='/archived/:id' element={<Logout />} />
                  <Route path='/archived' element={<Logout />} />
                  {/*<Route path='/actions' element={<Logout />} />*/}
                  {/*<Route path='/reports' element={<Logout />} />*/}
                  <Route path='/logout' element={<Logout />} />
                  <Route path={"*"} element={<PageNotFound/>} />
                  <Route path='/' element={<Logout />} />
                  <Route path='/callback' element={<Callback/>} />
                  <Route path='/oauth' element={<Authen/>} />
                </Routes>
            }
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </Fragment>
  );
};

export default App;
