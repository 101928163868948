import React from 'react';
const Iframe = (props:any) => {
   let iframe_ref = null;
   const writeHTML:any = (frame:any) => {
     if (!frame) {
       return;
     }
     iframe_ref = frame;
     let doc = frame.contentDocument;
     doc.open();
     doc.write(props.content);
     doc.close();
     const image = doc.querySelectorAll('img')
     for(let i = 0; i < image.length; i++) {
         if(image[i].getAttribute('src').split('://')[0] !== 'https'){
             image[i].setAttribute('src', 'data: image/jpeg' + ';base64,' + props.image[i])
         }
     }
     frame.style.width = '100%';
     frame.style.height =     `${frame.contentWindow.document.body.scrollHeight}px`;
   };
 return (
  <iframe id={'content-mail'} src="about:blank" ref={writeHTML}/>
 );
};
export default Iframe;
