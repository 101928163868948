  import React from 'react';
import {TextProps} from './Text.props';
import './Text.styles.css';

export const Text: React.FC<TextProps> = ({
  children,
  fontSize = 14,
  color= '#000000',
  cssClass= '',
  margin= '0',
  fontWeight,
  width,
  height,
  textAlign = '',
  backgroundColor = 'transparent',
}) => {
  return (
    <p className={`text-style ${cssClass}`} style={{height, width, fontSize, color, margin, fontWeight,textAlign, backgroundColor}}>
      {children}
    </p>
  );
};
