import appConfig from '../configs/appConfig.json';
import AWS from 'aws-sdk';
import { IAMClient } from "@aws-sdk/client-iam";
import {ListPoliciesCommand} from "@aws-sdk/client-iam";

interface LoginsInterface {
  [loginUrl: string] : any;
}

// Make AWS request using Cognito
export const makeCognitoRequest = (id_token:string) => {
  const aws_login_url = process.env.REACT_APP_AWS_CONFIG_LOGIN_URL || '';
  const aws_region = process.env.REACT_APP_AWS_CONFIG_REGION || '';
  const logins: LoginsInterface = {};
  // @ts-ignore
  logins[aws_login_url] = id_token;

  // *** REPLACE pool_id and table_name WITH YOUR OWN VALUES ***
  var pool_id = process.env.REACT_APP_AWS_CONFIG_POOL_ID || '';

  // Parameters required for CognitoIdentityCredentials
  var params = {
    IdentityPoolId: pool_id,
    Logins: logins
  };

  // Amazon Cognito region
  AWS.config.region = aws_region;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials(params);
  return new Promise((resolve, reject) => {
    AWS.config.getCredentials((err: any, credential:any) => {
      // localStorage.clear();
      if (err) {  // an error occurred
        //if(err.statusCode === 400 || err.statusCode === 403 || credential) {
        // let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL "];
        //
        // const isLogout = sessionStorage.getItem('isLogout');
        //
        // if (isLogout==='company_admin') {
        //   loginLink += "/company_admin";
        // } else {
        //   loginLink += "/admin";
        // }
        // window.location.href = loginLink;
        //}
        if (!credential) {
          localStorage.clear()
          window.location.href = '/logout';
        }
        reject(err);
      } else {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('sessionToken', credential.sessionToken);
        localStorage.setItem('accessKeyId', credential.accessKeyId);
        localStorage.setItem('secretAccessKey', credential.secretAccessKey);
        resolve(credential);
      }
    });
  });
}

export const getIAMRoleInfo = (accessKeyId:string, sessionToken:string) => {
  return new Promise((resolve, reject) => {
    const sts = new AWS.STS();
    const params = {}
    sts.getCallerIdentity(params, (err, data) => {
      if (err)
        reject(err);
      else {
        resolve(data);
      }
    });
  });
};

export const getListPolicies = (accessKeyId:string, secretAccessKey:string, sessionToken: string) => {
  return new Promise((resolve, reject) => {
    const CREDENTIAL = {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken
    };
    const iamClient = new IAMClient({ region: process.env.REACT_APP_AWS_CONFIG_REGION || '', credentials: CREDENTIAL});
    const params = {
      Marker: secretAccessKey,
      MaxItems: 20,
      OnlyAttached: false, /* Options are "true" or "false"*/
      PathPrefix: '/',
      PolicyUsageFilter: "PermissionsPolicy", /* Options are "PermissionsPolicy" or "PermissionsBoundary"*/
      Scope: "All" /* Options are "All", "AWS", "Local"*/
    }
    iamClient.send(new ListPoliciesCommand(params)).then((results) => {
      resolve(results);
    })
  });
};

