/* eslint-disable @typescript-eslint/no-explicit-any */
import {HttpMethod, httpClient} from './httpClient';
let userData:any = [];
const userLocalData = localStorage.getItem('userData');

if(userLocalData) {
  userData = JSON.parse(userLocalData);
}

const getZoneID = () => {
  return parseInt(localStorage.getItem('currentZone') || userData.zoneId)
}

export const getStaticReportDashboard = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/dashboard`, HttpMethod.Post, data);
};

export const getTriageInboxes = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/inboxes`, HttpMethod.Post, data);
};

export const updateTriageInboxes = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID().toString()
  data['adminId'] = userData.userId.toString()
  data['actionType'] = userData.role
  return httpClient(`/inboxes/update-triage-inbox`, HttpMethod.Post, data);
};

export const getInboxMessage = (messageID = {}): Promise<any> => {
  const data = {
    zoneId: getZoneID().toString()
  }
  return httpClient(`/inboxes/${messageID}`, HttpMethod.Post, data);
};

export const getStatusStatistic = (data = {}): Promise<any> => {
  data['zoneId'] = getZoneID()
  return httpClient(`/inboxes/status-statistic`, HttpMethod.Post, data);
};

export const getInboxMessageEmail = (messageID = {}): Promise<any> => {
  const data = {
    zoneId: getZoneID().toString()
  }
  return httpClient(`/inboxes/${messageID}/email`, HttpMethod.Post, data);
};

export const sendMessageEmail = (data= {}): Promise<any> => {
  return httpClient(`/inboxes/send-email`, HttpMethod.Post, data);
};

export const updateInboxMessage = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/update-triage-inbox`, HttpMethod.Post, data);
};

export const deleteEmail = (data = {}): Promise<any> => {
  return httpClient(`/delete-emails`, HttpMethod.Post, data);
};

export const archiveEmail = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/update-archive`, HttpMethod.Post, data);
};

export const purgeEmail = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/purge-email`, HttpMethod.Post, data);
};

export const getLatestFilter = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/get-filter-inbox`, HttpMethod.Post, data);
};

export const updateLatestFilter = (data = {}): Promise<any> => {
  return httpClient(`/inboxes/update-filter-inbox`, HttpMethod.Post, data);
};

