import React from 'react';
import {MailHeaderViewerProps} from "./MailHeaderViewer.props";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
export const MailHeaderViewer: React.FC<MailHeaderViewerProps> = ({
    content
}) => {
  const headersList = content;

  return (
    <Stack spacing={2}>
      { content.length === undefined ?  <p className={"nodata-content"}> No header were contained in this email</p> : headersList.map((item:any, index:any) => {
        return (<Box key={index}>
          <Typography sx={{fontWeight: '700'}} variant={'body2'} display="inline">{item.key}{`${': '}`}</Typography>
          <Typography sx={{wordWrap: 'break-word', wordBreak: 'break-all'}} variant={'body2'} display="inline">{item.value}</Typography>
        </Box>)
      })}
    </Stack>
  );
};
