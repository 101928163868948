/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, {AxiosResponse, AxiosError} from 'axios';
import aws4Interceptor from './aws4Interceptor';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-js';
import appConfig from "../configs/appConfig.json";
import { makeCognitoRequest } from '../api/AWS';
import { CONSTANTS } from '../constants/contants';

let accessToken: string | void;
const sigv4 = new SignatureV4({
  service: 'execute-api',
  region: process.env.REACT_APP_AWS_CONFIG_REGION || '',
  credentials: {
    accessKeyId: localStorage.getItem('accessKeyId') || '',
    secretAccessKey: localStorage.getItem('secretAccessKey') || '',
    sessionToken: localStorage.getItem('sessionToken') || '',
  },
  sha256: Sha256,
});

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export interface InterceptorOptions {
  service: string;
  region: string;
}

export interface ICredentials {
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken?: string;
}

const interceptor = aws4Interceptor(
  {
    region: process.env.REACT_APP_AWS_CONFIG_REGION || '',
    service: "execute-api",
  },
  {
    accessKeyId: localStorage.getItem('accessKeyId') || '',
    secretAccessKey: localStorage.getItem('secretAccessKey') || '',
    sessionToken: localStorage.getItem('sessionToken') || ''
  }
);

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  let originalRequest: any = error.config;

  //@ts-ignore
  if (error?.response.status === 401 && !originalRequest._retry) {
    //@ts-ignore
    originalRequest._retry = true;
    getAccessToken().then(token => {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      return axios(originalRequest);
    });
  }

  return Promise.reject(error);
};

const getEncodedZoneList = () => {
  return localStorage.getItem('encoded_zones')
}

axios.interceptors.request.use(interceptor, onRequestError);
axios.interceptors.response.use(onResponse, onResponseError);

export const httpClient = (url: string, method: HttpMethod, bodyData: any) => {
  return new Promise((resolve, reject) => {
    const baseURL = process.env.REACT_APP_BASE_API_URL;
    const hostName = process.env.REACT_APP_HOSTNAME || '';
    const params: any = {method, url: `${baseURL}${url}`};

    // @ts-ignore
    const signed = sigv4.sign({
      method: method,
      hostname: hostName,
      path: url,
      protocol: 'https',
      headers: {
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'x-meta-zones': getEncodedZoneList(),
        // 'Content-Type': 'application/json',
        host: hostName, // compulsory
      },
    });
    if(bodyData) {
      params.body = bodyData
      params.body = Object.assign({}, params.body, {zones: getEncodedZoneList()})
    } else {
      params.body = { zones: getEncodedZoneList() }
    }
    try {
      // getAccessToken().then((token:any) => {
      //   accessToken = token;
        const data = axios({
          ...signed,
          data: JSON.stringify(params.body),
          method: method,
          url: `${baseURL}${url}`, // compulsory
        });
        data.then((response) => {
          // console.log('Successfully received data: ', response);
          resolve(response);
        }).catch((error) => {
          if(error.response.data === undefined || error.response.data.message === "The security token included in the request is expired") {
            return
            localStorage.clear();
            let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL;

            const isLogout = sessionStorage.getItem('isLogout');

            if (isLogout==='company_admin') {
              loginLink += "/company_admin";
            } else {
              loginLink += "/admin";
            }
            window.location.href = loginLink;
          }
          reject(error);
        });
      // });
    } catch (error) {
      // console.log('An error occurred', error);
      reject(error);
    }
  });
};

// @ts-ignore
export const getAccessToken = (): Promise<string | void> => {
  const idToken = localStorage.getItem('idToken');

  const accessToken = makeCognitoRequest(idToken || '').then((result:any) => {
    return result.sessionToken;
  });

  return accessToken;
};