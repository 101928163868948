import React from 'react';
import {ControlDisplayProps} from './ControlDisplay.props';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import './ControlDisplay.styles.css';
import {Colors} from "../../../configs/colors"

export const ControlDisplay: React.FC<ControlDisplayProps> = ({
  label,
  content,
  labelWidth = "100px",
  labelAlign= "left",
  labelColor = Colors.LabelColor,
  labelFontWeight= "400",
  contentAlign= "left",
  contentColor= Colors.Black80,
  contentFontWeight= "400",
  contentFontStyle= "",
  boxDirection="row",
  boxAlign="flex-start", boxMb=2,
  display='flex',
  variant='body2',
  flexBasis="auto"
}) => {
    return (
        <Box sx={{display: display, flexDirection: boxDirection, alignItems: boxAlign, mb: boxMb}} className={'control-display'}>
            <Typography variant={variant} style={{
              width: labelWidth,
              color: labelColor,
              fontWeight: labelFontWeight,
              marginRight: 16,
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: flexBasis
            }}
                        className={'control-display-label'} align={labelAlign}>{label} </Typography>
          {content !== undefined ?
            <Typography variant={variant}
                         style={{color: contentColor, fontWeight: contentFontWeight, fontStyle: contentFontStyle}}
                         className={'control-display-content'} align={contentAlign}>{content}</Typography>
            :
            <Skeleton variant="text" height={20} sx={{fontSize: '1rem', width: '50%'}}/>
          }
        </Box>
    );
};
