import React, {Fragment} from 'react';
import {ScanResultViewerProps} from "./ScanResultViewer.props";
import Iframe from "../IFrame/IFrame";
import Skeleton from "@mui/material/Skeleton";
import {Stack} from "@mui/material";
import moment from 'moment';
import {ControlDisplay} from "../ControlDisplay";
import {useTheme} from "@mui/material/styles";
import './ScanResultViewer.style.css';

export const ScanResultViewer: React.FC<ScanResultViewerProps> = ({
    content
}) => {
  const theme = useTheme();


  if(content && content['scan-result'] === 'Clean') {
    content['message'] = 'No threat has been found'
  } else if (content && content['scan-result'] === 'Infected') {
    content['attachment'] = String(content['message'])
    content['message'] = 'Malware found'

  }

  // const scanStatus = content && <div>Scan Status: {content['scan-result']}</div>
  const scanTime = content && <div>Scan Time: {moment(content['date-scanned']).format('DD/MM/YYYY, hh:mm:ss')}</div>
  const status = content && (<span className={'scan-status ' + content['scan-result'].toLowerCase()}>{content['scan-result']}</span>)
  const scanStatus = content && <ControlDisplay boxAlign={'center'} labelFontWeight="600" label={'Scan result: '} labelAlign={'left'} labelColor={theme.palette.black_darker.main} contentColor={theme.palette.black_darker.main} content={status} labelWidth='120px'/>
  const scanDate = content && <ControlDisplay labelFontWeight="600" label={'Date scanned: '} labelAlign={'left'} labelColor={theme.palette.black_darker.main} contentColor={theme.palette.black_darker.main} content={moment(content['date-scanned']).format('DD/MM/YYYY hh:mm A')} labelWidth='120px'/>
  const scanDescription = content && content['message'] && <ControlDisplay labelFontWeight="600" label={'Description: '} labelAlign={'left'} labelColor={theme.palette.black_darker.main} contentColor={theme.palette.black_darker.main} content={content['message']} labelWidth='120px'/>


  return (
    <Fragment>
      {
        content === undefined ?
          (<Skeleton variant="rounded" width={'100%'} height={60} />)
        :
          (
            <Stack>
              {scanStatus}
              {scanDate}
              {scanDescription}
              {content && content['scan-result'] === 'Infected' && <table width={'100%'} className={'table-scan-result'} >
                <thead >
                <tr>
                  <th>Virus name</th>
                  <th>Attachment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{content && content['virus-name']}</td>
                  <td>{content && content['attachment']}</td>
                </tr>
                </tbody>
              </table>
              }
            </Stack>
          )
      }
    </Fragment>
  );
};
