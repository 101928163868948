import React, {useEffect, useRef, useState} from 'react';
import {CustomDropDownProps} from './CustomDropDown.props';
import DropDownContainer from '../../atoms/DropDownContainer/DropDownContainer';
import MenuItem from "@mui/material/MenuItem";
import {ListItem} from "@mui/material";
import TextField from "@mui/material/TextField";
import { ReactComponent as SearchIcon } from "../../../assets/images/searchIcon.svg";


function filterTree(array:any, text:string) {
    const getNodes = (result:any, object:any) => {
        if (object.name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
            result.push(object);
            return result;
        }
        if (Array.isArray(object.children)) {
            const nodes = object.children.reduce(getNodes, []);
            if (nodes.length) result.push({ ...object, nodes });
        }
        return result;
    };

    return array.slice().reduce(getNodes, []);
}

export const CustomDropDown: React.FC<CustomDropDownProps> = ({masterNode, dataSource=[], ...props}) => {
    const [dataList, setDataList] = useState(dataSource)

    const onTextSearch = (searchText: string, array:any) => {
        let filteredOptions = filterTree(array, searchText)
          setDataList(filteredOptions)
    }

    return (
        <DropDownContainer
            label={masterNode.name}
            onNodeClick={() => props.onItemClick(masterNode)}
            onClickChange={() => setDataList(dataSource)}
            hasChild={dataSource.length > 0}
        >
            <div className={'dropdown-menu-wrapper'}>
                <div className={'dropdown-menu-header'}>
                    <ListItem>
                        <TextField
                          sx={{fontSize: "12px"}}
                          fullWidth
                          variant={'standard'}
                          placeholder={"Search"}
                          onChange={(e) => {
                              onTextSearch(e.target.value, dataSource);
                          }}
                          onKeyDown={e => {
                              e.stopPropagation();
                          }}
                          onFocus={e => {
                              e.stopPropagation();
                          }}
                          onClick={e => {
                              e.stopPropagation();
                          }}
                        />
                        <SearchIcon className={'search-icon'}/>
                    </ListItem>
                </div>
                <div className={'dropdown-menu-body'}>
                   {dataList.length > 0 ?
                     dataList.map((zone:any, index:any) => (
                        <MenuItem
                          key={index}
                          selected={masterNode.id == zone.id}
                          onClick={(event) => {props.onItemClick(zone)}}
                        >
                          {zone.name}
                        </MenuItem>
                      ))
                     :
                     <div className={'no-record-found'}>No record found</div>
                   }
                </div>
            </div>
        </DropDownContainer>
    );
};
