import  React, {useState} from 'react';
import {EditorProps} from './Editor.props';
import './Editor.styles.css';
import JoditEditor from "jodit-react";
import {isMobileOnly} from "react-device-detect";

export const Editor: React.FC<EditorProps> = ({
}) => {
  const editorConfig:any = {
    readonly: false,
    placeholder: 'Typing something here...',
    toolbar: isMobileOnly ? false : true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showWordsCounter: true,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    uploader: {
      insertImageAsBase64URI: true,
      url: ''
    },
    filebrowser: {
      ajax: {
        url: ''
      }
    },
    buttons: [
      'bold',
      'strikethrough',
      'underline',
      'italic', '|',
      'ul',
      'ol', '|',
      'outdent', 'indent',  '|',
      'font',
      'fontsize',
      'brush',
      'paragraph', '|',
      'image',
      'table',
      'link', '|',
      'align', 'undo', 'redo', '|',
      'hr',
      'eraser',
      'copyformat', '|',
      'fullsize',
    ],
    width: 800,
    height: 320,
  };
  const [editorText, setEditorText] = useState<string>("");
  return (
    <JoditEditor
      value={editorText}
      config={editorConfig}
      onBlur={(newContent) => setEditorText(newContent)}
      // @ts-ignore
      tabIndex={1}
      onChange={newContent => {}}
    />
  );
};
