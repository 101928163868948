import React, {useEffect, useState} from 'react';
import {ToastProps} from './Toast.props';
import './Toast.styles.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as SuccessToast } from "../../../assets/images/check-toast.svg";

export const Toast: React.FC<ToastProps> = ({
    isOpen,
    type = 'success',
    autoHideDuration,
    message,
    toastId
}) => {
    const [open, setOpen] = useState(false);
    const vertical = 'bottom';
    const horizontal = 'right';

    const handleClose = () => {
        setOpen(false);
    };
    let icon = <SuccessToast className={'btnNode'}/>
    if (type === 'error'){
        icon = <></>
    }

    useEffect(() => {
        if(message && isOpen){
            setOpen(true)
        }
    }, [toastId])

    return (
      <Snackbar
        anchorOrigin= {{ vertical: 'bottom', horizontal: 'right' }}
        key={ vertical + horizontal }
        autoHideDuration={autoHideDuration}
        open={open}
        onClose={handleClose}
        message={message}
        action = {<IconButton
          aria-label="close"
          color="inherit"
          sx={{ p: 0.5 }}
          onClick={handleClose}
        >
            <CloseIcon />
        </IconButton>}
      >
          <Alert onClose={handleClose} severity={type || 'success'} sx={{ width: '100%' }} action={<span className={'icon-close'} onClick={handleClose}>CLOSE</span>}>
              {message}.
          </Alert>
      </Snackbar>

    );
};
