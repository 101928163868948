import React from 'react';
import {ButtonProps} from './Button.props';
import './Button.styles.css';
import {Image} from '../Image'
import {Text} from '../Text'

export const Button: React.FC<ButtonProps> = ({
    cssClass,
    actived= false,
    variant='button-style',
    fontSize = 14,
    color= '#fff',
    width= '216px',
    height= '48px',
    backgroundColor= "",
    border = 2,
    borderRadius= "none",
    title,
    linkIcon,
    onClick,
    fontWeight,
    margin,
    titleMargin = '0 10px',
    txtWidth,
    textAlign,
    iconMargin,
    typeBtn,
    iconWidth = "19px",
    iconHeigth= "19px",
    component,
    tooltipComponent,
    disabled,
    ...rest

}) => {
    return (
        <button type={typeBtn} onClick={onClick} className={`button ${variant} ${cssClass} ${actived?'active':''}`} style={{margin, width, height, backgroundColor, border, borderRadius,...rest }} {...rest} disabled={disabled}>
            {linkIcon && <Image width={iconWidth} height={iconHeigth} margin={`${iconMargin}`} srcImg={linkIcon} />}
            {component}
            {title && <Text textAlign={textAlign} children={title} fontSize={fontSize} margin={titleMargin} width={txtWidth} color={color} fontWeight={`${fontWeight}`}/>}
            {tooltipComponent}
        </button>
    );
};
