import React, {useState} from 'react';
import {ModalProps} from './Modal.props';
import './Modal.styles.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Button} from "../../atoms/Button";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import {Checkbox, FormControlLabel} from "@mui/material";

export const Modal: React.FC<ModalProps> = ({
    cssClass = 'default-dialog',
    variant,
    idModal,
    openDialog,
    title,
    content,
    actionAgree,
    actionCancel,
    textAgree= 'Agree',
    textCancel= 'Cancel',
    children,
    onClose,
    fullScreen= false,
    handleOnChange,
    disableEnforceFocus
}) => {
    let icon:any
    if (variant === 'delete'){
        icon = <ErrorIcon style={{marginRight: '10px'}}/>
    } else if(variant === 'success'){
        icon = <CheckCircleIcon style={{marginRight: '10px'}}/>
    }
    return (
      <Dialog
        disableEnforceFocus={disableEnforceFocus}
        fullScreen={true}
        open={openDialog}
        onClose={actionCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id={idModal}
        className={cssClass}
        onClick={(e) => e.stopPropagation()}
      >
          <DialogTitle id="alert-dialog-title">
              {icon}
              {title}
              {onClose ? (
                <IconButton
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      right: 8,
                      top: 12,
                      color: '#666666',
                  }}
                >
                    <CloseIcon />
                </IconButton>
              ) : null}
          </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  {content}
              </DialogContentText>
              {children}
          </DialogContent>
          <DialogActions id='alert-dialog-action'>
              {variant === 'send-mail' ? <FormControlLabel control={<Checkbox id={'to-resolved'} onChange={handleOnChange} defaultChecked/>} label="Change status to Resolved"/> : null}
              <div className={'action-btn'} style={{display: "flex"}}>
                  {actionCancel ? <Button title={textCancel}
                                          variant={'outline'}
                                          backgroundColor={'transparent'}
                                          width={'fit-content'} height={'auto'}
                                          fontSize={16}
                                          fontWeight={'600'}
                                          color={'#011111'}
                                          border={'1px solid #E0E0E0'}
                                          borderRadius={'4px'}
                                          padding={'10px 16px'}
                                          onClick={actionCancel}/> : null}
                  {actionAgree ? <Button title={textAgree}
                                         cssClass={`agree ${variant}`}
                                         variant={'outline'}
                                         width={'fit-content'} height={'auto'}
                                         fontSize={16}
                                         fontWeight={'600'}
                                         color={'#fff'}
                                         borderRadius={'4px'}
                                         padding={'10px 16px'}
                                         margin={'0 0 0 8px'}
                                         onClick={actionAgree}/> : null}
              </div>
          </DialogActions>
      </Dialog>
    );
};
