import React, {useState} from 'react';
import {DropdownContentProps} from "./DropdownContent.props";
import './DropdownContent.styles.css';
import {IconButton, Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import {Modal} from "../../atoms/Modal";
import {PURGE_TITLE, MESSAGES_STATUS_CHANGED, MESSAGES_PRIORITY_CHANGED} from "../../../constants/contants";

export const DropdownContent: React.FC<DropdownContentProps> = ({
   menuData,
   handleOpenSendMail,
   handleDeleteMail,
   handleArchiveMail,
   actionRulesData,
   actionOtherData,
   actionPhishData,
   handlePurgeMail,
 }) => {
  const zoneID:any = localStorage.getItem('currentZone');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [contentModal, setContentModal] = useState({title: '', content: '', action: '', textAgree: '', status: '', priority: '', children: '', variant: ""});

  const handleOpenConfirm = (action?:any) => {
    let popupContent = ''
    let html = ``
    let title = action + ' message'
    let textAgree = action === "Delete" ? "Delete" : 'Archive'
    if (action === 'Purge'){
        title = PURGE_TITLE
        html = `<div>The original emails of the selected messages will be permanently remove from all users’ inboxes of the current zone. 
                    <br> Are you sure you want to remove them?</div>
                <div class="notes">
                    <div>${MESSAGES_STATUS_CHANGED}: <span>Resolved</span></div>
                    <div>${MESSAGES_PRIORITY_CHANGED}: <span>Low</span></div>
                </div>`
        textAgree = 'Remove'
    } else if (window.location.pathname.split('/')[2]){
        popupContent = `Are you sure you want to ${action.toLowerCase()} this message?`
    } else if(menuData.length === 1){
        popupContent = `Are you sure you want to ${action.toLowerCase()} 1 selected message?`
    } else {
        popupContent = `Are you sure you want to ${action.toLowerCase()} ` + menuData.length + ' selected messages?'
    }
    const content:any = {
      title: title,
      content: popupContent,
      children: action === 'Purge' ? <div dangerouslySetInnerHTML={{ __html: html }} /> : '',
      action: action,
      textAgree: textAgree,
      variant: action === 'Archive' || action === 'SendMail' ? 'default' : 'delete'
    }
    setOpenConfirm(true);
    setContentModal(content)
  };

  const handleClose = () => {
    setOpenConfirm(false);
  };

  const handleAction = (rule:string) => {
    switch (rule){
      case 'SendMail':
        return handleOpenSendMail(rule);
      case 'Delete':
        let emailDelete = []
        if(menuData[0].email_id){
          emailDelete.push({
            'email_id': menuData[0].email_id,
            'zone_id': zoneID.toString()
          })
        }
        return handleDeleteMail(emailDelete);
      case 'Archive':
        let emailArchive = []
        if(menuData[0].email_id){
          emailArchive.push({
            'email_id': menuData[0].email_id,
          })
        }
        return handleArchiveMail(emailArchive);
      case 'Purge':
        let emailPurge = []
        if(menuData[0].email_id){
          emailPurge.push({
            'email_id': menuData[0].email_id,
          })
        }
        handlePurgeMail(emailPurge)
    }
  }

  const actionList:any = (
    actionRulesData ?
      <div className='actions-rules'>
        <div style={{padding:'10px 0'}}>
          <span className='action-title'>Action rules</span>
          <Tooltip placement={"top"} title="Each action rules include a set of actions is pre-defined in the action rules page">
            <IconButton className='info-action'>
              <HelpIcon fontSize={'small'} color={"secondary"} />
            </IconButton>
          </Tooltip>
        </div>
        <ul>
          {
            Object.keys(actionRulesData).map((rule:string) => <li key={rule}>{actionRulesData[rule]}</li>)
          }
        </ul>
      </div> : <></>
  )

  const otherList:any = (
    actionOtherData ?
    <div className='actions-rules for-email'>
      {/*<div className='action-title'>Others</div>*/}
      <ul>
        {
          Object.keys(actionOtherData).map((rule:string) =>
              menuData.length >= 2 && rule === 'SendMail' ?
                  <Tooltip key={rule} placement={"top"} title='You can’t send email when choosing multiple messages.'>
                      <li key={rule} className='disabled'>{actionOtherData[rule]}</li>
                  </Tooltip> :
                  <li key={rule} onClick={() => rule === 'Delete' ||  rule === 'Archive' || rule === 'Purge' ? handleOpenConfirm(rule) : handleAction(rule)}>{actionOtherData[rule]}</li>
          )
        }
      </ul>
    </div> : <></>
  )

  const phishList:any = (
    actionPhishData ?
    <div className='actions-rules for-email'>
      <div className='action-title'>Phish Hook</div>
      <ul>
        {
          Object.keys(actionPhishData).map((rule:string) => <li key={rule}>{actionPhishData[rule]}</li>)
        }
      </ul>
    </div> : <></>
  )
  return (
    <div>
      {actionList}
      {otherList}
      {phishList}
      <Modal idModal='modal-delete-mail'
             openDialog={openConfirm}
             variant={contentModal.variant}
             title={contentModal.title}
             content={contentModal.content}
             actionAgree={() => {
               handleAction(contentModal.action)
               handleClose()
             }}
             actionCancel={handleClose}
             textAgree={contentModal.textAgree || 'Accept'}
             onClose={handleClose}
             children={contentModal.children}
      />
    </div>
  )
};
