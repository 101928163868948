import React, {useState} from 'react'
import {SearchMenuBarProps} from './SearchMenuBar.props'
import './SearchMenuBar.styles.css';
import {Images} from '../../../configs/images';
import {SearchFilter} from '../../molecules/SearchFilter';
import {isMobileOnly} from "react-device-detect";
import {Button} from '../../atoms/Button';
import {Image} from '../../atoms/Image';
import {DropDownFilter} from "../../molecules/DropDownFilter";
import moment from "moment";

export const SearchMenuBar: React.FC<SearchMenuBarProps> = ({
  onFilter,
  defaultFilter,
  handleEnterSearch,
  setSelectedItems,
  selectedItems,
  resetFilter
}) => {
  const sDate = moment().utc().subtract(1, 'months').format('MM/DD/YYYY');
  const eDate = moment().utc().format('MM/DD/YYYY');
  const filterResult: any = defaultFilter;
  const [filterDataResult, setFilterDataResult] = useState<any>(filterResult);


  const arrayStatus:any = [
    {id: 1, content: 'In Review'},
    {id: 2, content: 'Resolved'},
    {id: 3, content: 'Unknown'},
  ];

  const arrayPriority:any = [
    {id: 1, content: 'High'},
    {id: 2, content: 'Medium'},
    {id: 3, content: 'Low'},

  ];

  const arrayCategory:any = [
    {id: 1, content: 'Threat'},
    {id: 2, content: 'Spam'},
    {id: 3, content: 'Clean'},
    {id: 4, content: 'Unknown'},
  ];

  const arrayScanResult:any = [
    {id: 1, content: 'Infected'},
    {id: 2, content: 'Unscannable'},
    {id: 3, content: 'Error'},
    {id: 4, content: 'Clean'}
  ];

  const [arrStatus, setArrStatus] = useState<any>(arrayStatus)
  const [arrPriority, setArrPriority] = useState<any>(arrayPriority)
  const [arrCategory, setArrCategory] = useState<any>(arrayCategory)
  const [arrScan, setArrScanResult] = useState<any>(arrayScanResult)
  const [isShowFilter, SetShowFilter] = useState<boolean>(false)
  const [dates, setDates] = useState({
    startDate: sDate,
    endDate: eDate,
  });

  const onSearch = (searchText: string, array:any, type:string) => {
    let filteredOptions =
      array.filter((item:any) => {
        if(searchText.length !== 0){
          return (item.content.toString().toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
        } else {
          return true
        }
      });
    if (type === 'status'){
      setArrStatus(filteredOptions)
    } else if (type === 'priority'){
      setArrPriority(filteredOptions)
    } else if (type === 'category') {
      setArrCategory(filteredOptions)
    } else if (type === 'scan'){
      setArrScanResult(filteredOptions)
    }
  }
  const filterSelection = (item:any, type:any) => {
    // set value for dropdown
    setSelectedItems((prev:any) => {
      return  ({
        ...prev,
        [type]: item,
      })
    })

    if(typeof item === 'object') {
      if(item.length > 0){
        filterDataResult[type] = item;
      } else {
        Object.assign(filterDataResult[type], defaultFilter[type]);
      }
    }
    setFilterDataResult(filterDataResult);
    sessionStorage.setItem("filter", JSON.stringify(filterDataResult));
    onFilter && onFilter(filterDataResult)
  }

  const resetFilterResult = () => {
    setFilterDataResult(defaultFilter)
  }

  const clearFilerSelection = (type:any) => {
    setSelectedItems((prev:any) => {
      return  ({
        ...prev,
        [type]: [],
      })
    })
  }

  const handleFilterDateRange = (start?:string, end?:string) => {
    setFilterDataResult((prev:any) => {
      return ({
        ...prev,
        startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
      })
    })
    Object.assign(filterDataResult, {
      startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
      endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss')
    });
    onFilter && onFilter(filterDataResult)
  }

  const handleSearchKeyword = (pValue:any) => {
    setFilterDataResult((prev:any) => {
      return ({
        ...prev,
        keyword: pValue,
      })
    });
  }
  return <div className={'search-menu'}>
    <SearchFilter
      height={38}
      margin={'0'}
      widthTextInput={180}
      titleInput={'Search'}
      iconSrc={Images.SearchIcon}
      borderRadius={'4px'}
      handleKeyDownEvent={handleEnterSearch}
      onSearchEvent={handleSearchKeyword}
    />
    {
      isMobileOnly && <div className={'more-filter ' + (isShowFilter ? 'hide-icon' : '')} onClick={()=>SetShowFilter(!isShowFilter)}><Image srcImg={Images.FilterIcon} width={18}/></div>
    }

    <div className={'search-menu-right ' + (isMobileOnly && 'mobile-popup ') + (isShowFilter ? 'show-filter ': 'hide-filter')}>
      {isMobileOnly && <div className='filter-title-mb'>Filter <Image onClick={()=>SetShowFilter(false)} srcImg={Images.CloseGray} width={18}/></div>}
      <DropDownFilter clearFilerSelection={clearFilerSelection} onSelectItem={filterSelection} selectedItems={selectedItems} label={'Priority'} type={'priority'} arrList={arrPriority} setSearchQuery={(e:any) => onSearch(e, arrayPriority, 'priority')} width={95}/>
      <DropDownFilter clearFilerSelection={clearFilerSelection} onSelectItem={filterSelection} selectedItems={selectedItems} label={'Category'} type={'category'} arrList={arrCategory} setSearchQuery={(e:any) => onSearch(e, arrayCategory, 'category')} width={107}/>
      <DropDownFilter clearFilerSelection={clearFilerSelection} onSelectItem={filterSelection} selectedItems={selectedItems} label={'Scan Results'} type={'scans'} arrList={arrScan} setSearchQuery={(e:any) => onSearch(e, arrayScanResult, 'scans')} width={132}/>
      <div className={isMobileOnly ? 'bottom-buttons-popup': ''}>
        <Button title={'Reset'}
                backgroundColor={'transparent'}
                variant={'reset-btn'}
                width={44} height={24}
                fontSize={16}
                fontWeight={'600'}
                color={'#467599'}
                margin={'0 12px 0 0'}
                onClick={() => { resetFilter(); resetFilterResult() }}
        />
        {isMobileOnly && <Button cssClass={'btn-done'} title='Done' onClick={()=>SetShowFilter(false)} width={89} backgroundColor={'#345C8B'}/>}
      </div>
    </div>
  </div>
}
