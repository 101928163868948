import React, {useEffect, useState} from 'react';
import {Chip} from "@mui/material";
import {MailViewer} from "../../molecules/MailViewer";
import {TabGroup} from "../../molecules/TabGroup";
import {MailHeaderViewer} from "../../molecules/MailHeaderViewer";
import {MailAttachmentViewer} from "../../molecules/MailAttachmentViewer";
import {MailUrlsViewer} from "../../molecules/MailUrlsViewer";
import {getInboxMessageEmail} from "../../../api/emailTriage";
import {ScanResultViewer} from "../../molecules/ScanResultViewer";
import {isMobileOnly} from "react-device-detect";


export const EmailDetails: React.FC<any> = (props) => {
  const [attachmentsList, setAttachmentsList] = useState([]);
  const [urlList, setUrlList] = useState([]);
  const [totalAttachment, setTotalAttachment] = useState(0);
  const [totalURLs, setTotalURLs] = useState(0);
  const [emailBody, setEmailBody] = useState<any|undefined>()
  const [emailHeaders, setEmailHeaders] = useState({})
  const [scanResult, setScanResult] = useState(undefined)
  const [tabValue, setValue] = useState(0);
  const [srcImage, setSrcImage] = useState({})

  const handleGetImage = (src:any) => {
    if(src) {
      for(let i = 0; i < src.length; i++) {
        if(src[i].mail_content_type.includes("image/")) {
          const base64Img = src[i].payload
          setSrcImage((prev:any) => {
            return ({
              ...prev,
              [i]: base64Img
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    getInboxMessageEmail(props.emailID).then((result) => {
      setEmailBody(result.data.body_html.length > 0 ? result.data.body_html : result.data.body_plain)
      setEmailHeaders(result.data.header)
      setAttachmentsList(result.data.attachments)
      setUrlList(result.data.urls)
      setTotalAttachment(result.data.attachments.length)
      setTotalURLs(result.data.urls.length)
      setScanResult(result.data.scan_results || {})
      handleGetImage(result.data.attachments)
      props.setOriginalEmail(result.data.original_email)
      props.setScanResult(result.data.scan_results || {})
    }).catch((error) => {
      setEmailBody('')
      // console.log("Error", error)
    })
  }, [])

  const headerTableTitles = [
    <div>SCAN RESULTS</div>,
    <div>EMAIL CONTENT</div>,
    <div>HEADER</div>,
    <div>ATTACHMENTS {totalAttachment >= 0 ?
      (isMobileOnly ? "(" + totalAttachment + ")" : <Chip sx={{backgroundColor: '#808888', color: 'white'}} size={'small'} label={totalAttachment}/>): null}</div>,
    <div>URLS {totalURLs >= 0 ?
      (isMobileOnly ? "(" + totalURLs + ")" : <Chip sx={{backgroundColor: '#808888', color: 'white'}} size={'small'} label={totalURLs}/>) : null}</div>,
  ]

  const tabContents = [
    <ScanResultViewer content={scanResult} />,
    <MailViewer content={emailBody} image={srcImage}/>,
    <MailHeaderViewer content={emailHeaders}/>,
    <MailAttachmentViewer content={attachmentsList}/>,
    <MailUrlsViewer content={urlList}/>,
  ]

  return (
    <div id={'email-detail'}>
      <TabGroup setValue={setValue} tabValue={tabValue} tabTitles={headerTableTitles} tabContents={tabContents} setActiveFilter={(e:any) => {console.log(e)}}/>
    </div>
  );
};
