import React, { useEffect, useState} from 'react';
import moment from 'moment';
import {makeCognitoRequest} from '../../api/AWS'

const IdleTimeOutHandler = (props)=>{
    const[isLogout,setLogout]=useState(localStorage.getItem('isLoggedIn')!=='true')
    let timer=undefined;
    const events= ['click','load','keydown']
    const eventHandler =()=>{
        if(!isLogout){
            localStorage.setItem('lastInteractionTime',moment() )
            if(timer){
                props.onActive();
                startTimer();
            }
        }
    };

    useEffect(()=>{
        addEvents();

        return (()=>{

            removeEvents();
            clearTimeout(timer);
        })
    },[])

    const startTimer=()=>{

        if(timer){
            clearTimeout(timer)
        }
        timer = setTimeout(()=>{

            let lastInteractionTime=localStorage.getItem('lastInteractionTime')
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));

            let timeOutInterval=props.timeOutInterval?props.timeOutInterval:1200000;

            if(isLogout){
                clearTimeout(timer)
            }else{
                if(diff._milliseconds<timeOutInterval){
                    // if (diff._milliseconds  >= 600000) {
                    //     const idToken = localStorage.getItem('idToken');
                    //     makeCognitoRequest(idToken).then(() => {})
                    // }
                    startTimer();
                    props.onActive();
                }else{
                    props.onIdle();
                    removeEvents();
                    clearTimeout(timer);
                    //@ts-ignores
                    const userLocalData = JSON.parse(localStorage.getItem('userData'));
                    sessionStorage.setItem("isTimeout", "true");
                    sessionStorage.setItem("isLogout", userLocalData['role']);
                    window.location.href = '/logout';
                    localStorage.clear();
                }
            }

        },props.timeOutInterval?props.timeOutInterval:10000)
    }
    const addEvents=()=>{

        events.forEach(eventName=>{
            window.addEventListener(eventName,eventHandler)
        })

        startTimer();
    }

    const removeEvents=()=>{
        events.forEach(eventName=>{
            window.removeEventListener(eventName,eventHandler)
        })
    };

    // const handleContinueSession = ()=>{
    //     // setShowModal(false)
    //     // setLogout(false)
    //     console.log("CONTINUE SESSION")
    // }
    // const handleLogout = ()=>{
    //     removeEvents();
    //     clearTimeout(timer);
    //     console.log("LOGOUT QQ")
    //     // setLogout(true)
    //     // props.onLogout();
    //     // setShowModal(false)
    // }

    return(
        <div></div>
    )
}

export default IdleTimeOutHandler;