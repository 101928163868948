import React, {useEffect, useState} from 'react';
import {AlertMessageProps} from './AlertMessage.props';
import './AlertMessage.styles.css';
import Alert from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

export const AlertMessage: React.FC<AlertMessageProps> = ({
    variant = 'filled',
    severity = 'error',
    content,
}) => {
    const [icon, setIcon] = useState<any>(null)
    const handleIcon = () => {
        switch (severity){
            case 'success':
                setIcon(<TaskAltIcon/>)
                break;
            case 'error':
                setIcon(<ErrorIcon/>)
                break;
            case 'warning':
                setIcon(<WarningIcon/>)
                break;
            case 'info':
                setIcon(<InfoIcon/>)
                break;
        }
    }
    useEffect(() => {
        handleIcon()
    },[])
    return (
        <Alert className='alert-container' variant={variant} severity={severity} icon={icon}>
            {content}
        </Alert>
    );
};
