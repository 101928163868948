import {CONSTANTS} from "../constants/contants";

export const getIdToken = (tokenName:string='id_token') => {
  // Capture the redirected URL
  var url = window.location.href;

  // Extract id token from the id_token parameter
  var match = url.match(tokenName+'=([^&]*)');
  if (match) {
    var id_token = match[1]; // String captured by ([^&]*)

    // Make AWS request using the id token
    if (id_token) {
      return id_token
    }else{
      return false
    }
  }else{
    // console.log('Error: There was no id token in the URL');
    return false
  }
}

export const generateDayWiseTimeSeries = (baseval:any, count:any, yrange:any) => {
  let i = 0;
  let series = [];
  while (i < count) {
    let x = baseval;
    let y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([x, y]);
    baseval += 86400000;
    i++;
  }
  return series;
}

export const toPages = (pages: number) =>{
  const results = [];

  for (let i = 1; i <= pages; i++) {
    results.push(i);
  }

  return results;
}


export const setPriorityColor = (priority: string, icon: string) => {
  if (icon === "color") {
    switch (priority) {
      case 'Low':
        return CONSTANTS.PRIORITIES.Low.color;
      case 'High':
        return CONSTANTS.PRIORITIES.High.color;
      case 'Medium':
        return CONSTANTS.PRIORITIES.Medium.color;
    }
  }
  else {
    switch (priority) {
      case 'Low':
        return CONSTANTS.PRIORITIES.Low.linkIcon;
      case 'High':
        return CONSTANTS.PRIORITIES.High.linkIcon;
      case 'Medium':
        return CONSTANTS.PRIORITIES.Medium.linkIcon;

    }
  }
}

export const setCateColor = (cate: string) => {
  switch (cate) {
    case 'Threat':
      return CONSTANTS.CATEGORIES.Threat;
    case 'Clean':
      return CONSTANTS.CATEGORIES.Clean;
    case 'Spam':
      return CONSTANTS.CATEGORIES.Spam;
    default:
      return CONSTANTS.CATEGORIES.Unknown;
  }
}

export const setStatusColor = (status: string, type: string) => {
  if (type !== 'text') {
    switch (status) {
      case 'Resolved':
        return CONSTANTS.STATUS.Resolved.bgColor;
      case 'In Review':
        return CONSTANTS.STATUS.InReview.bgColor;
      default:
        return CONSTANTS.STATUS.Received.bgColor;
    }
  }
  else {
    switch (status) {
      case 'Resolved':
        return CONSTANTS.STATUS.Resolved.color;
      case 'In Review':
        return CONSTANTS.STATUS.InReview.color;
      default:
        return CONSTANTS.STATUS.Received.color;
    }
  }
}
