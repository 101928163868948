import React, {useState, useEffect} from 'react';
import { makeCognitoRequest } from '../../api/AWS';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getIdToken} from '../../utils/common';
import {CallbackProps} from './Callback.props';
import './Callback.style.css';
import { Image } from '../../components/atoms/Image';
import { Images } from '../../configs/images';
import jwt_decode from "jwt-decode";
import appConfig from "../../configs/appConfig.json";

export const Callback: React.FC<CallbackProps> = (props) => {
  const navigate = useNavigate()
  const [sToken, setSToken] = useState<string>('');
  const [accessKeyId, setAccessKeyId] = useState<string>('');
  const [secretAccessKey, setSecretAccessKey] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const idToken = getIdToken();
    const accessToken:any = getIdToken('access_token');
    const currentZone = searchParams.get("q");

    // Temporarily disable
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');

    makeCognitoRequest(idToken || '').then((result:any) => {

      if(result && !result.expired) {
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('idToken', idToken || '');
        localStorage.setItem('accessToken', accessToken || '');
        if (currentZone && currentZone.length > 0) {
          localStorage.setItem('currentZone', currentZone)
        }
        setSToken(result.sessionToken);
        setAccessKeyId(result.accessKeyId);
        setSecretAccessKey(result.secretAccessKey);
        if(idToken) {
          const decoded:any = jwt_decode(idToken);
          if(decoded) {
            const userData = {
              userId: decoded.user_id,
              fullName: decoded.full_name,
              group: decoded.group,
              email: decoded.email,
              role: decoded.role,
              zoneId: decoded.zone_id,
              tz_offset: decoded.tz
            }
            localStorage.setItem('userData', JSON.stringify(userData));
          }
        }

        window.location.href = '/';
      } else {
        localStorage.setItem('isLoggedIn', '');
        let loginLink =   "https://"+process.env.REACT_APP_AWS_CONFIG_LOGIN_URL;

        const isLogout = sessionStorage.getItem('isLogout');

        if (isLogout==='company_admin') {
          loginLink += "/company_admin";
        } else {
          loginLink += "/admin";
        }
        window.location.href = loginLink;
      }
    });
  }, []);

  return (
    <div className={'full-page'}>
      <div className="spinner-wrap">
        <Image width="75px" srcImg={Images.Spinner} />
      </div>
    </div>
  );
};

